<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <LocationModal updatable use-geocoder @save="onSaveLocation" />

    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <div class="grid grid-cols-2 gap-4">
        <TextInput
          v-model="form.name"
          rules="required"
          label="Fleet Name"
          placeholder="Fleet name"
        />

        <ValidationProvider
          tag="div"
          name="Organization"
          rules="required"
          v-slot="{ errors }"
        >
          <InputLabel :error="errors[0]" text="Organization *" />
          <TRichSelect
            v-model="form.organization"
            :options="organizations"
            :value-attribute="`id`"
            :text-attribute="`name`"
            :placeholder="`Select organization`"
            hide-search-box
          />
          <InputErrorItem :message="errors[0]" />
        </ValidationProvider>
      </div>

      <div class="flex relative items-center mb-6">
        <div class="w-11/12">
          <!-- <places-autocomplete @place-changed="onPlaceChange" /> -->
          <PlacesAutocomplete
            @update:place="onUpdatePlace"
            @clear:place="() => (place = null)"
          />
        </div>

        <div class="flex justify-center items-center w-1/12 google-map">
          <div class="mt-6 mr-0 map-btn" @click="$modal.show('locationModal')">
            <i class="fas fa-map" />
          </div>
        </div>
      </div>

      <div class="flex items-center">
        <TextInput
          v-model="form.warehouse_name"
          rules="required"
          label="Ware House Name"
          placeholder="Ware House Name"
          disabled
        />
      </div>

      <div class="grid grid-cols-2 gap-4">
        <TextInput
          v-model="form.warehouse_lon"
          rules="required"
          label="Longitude"
          placeholder="Longitude"
          disabled
        />

        <TextInput
          v-model="form.warehouse_lat"
          rules="required"
          label="Latitude"
          placeholder="Latitude"
          disabled
        />
      </div>

      <TCheckbox
        v-model="form.end_ride_picture"
        :label="`End Ride Photo Required`"
        :name="`End Ride Photo Required`"
        wrapped
      />

      <TCheckbox
        v-model="form.use_company_details"
        :label="`Use Company Details`"
        :name="`Use Company Details`"
        wrapped
      />

      <br />

      <div class="grid grid-cols-2 gap-4">
        <ValidationProvider
          tag="div"
          name="Country"
          rules="required"
          v-slot="{ errors }"
        >
          <InputLabel :error="errors[0]" text="Country *" />
          <TRichSelect
            v-model="form.country"
            :options="countries"
            :value-attribute="`id`"
            :text-attribute="`name`"
            :placeholder="`Select a country`"
            :variant="{
              danger: !!errors[0],
              readonly: getCompanyDetailsDisabledState,
            }"
            :disabled="getCompanyDetailsDisabledState"
          />
          <InputErrorItem :message="errors[0]" />
        </ValidationProvider>

        <ValidationProvider
          tag="div"
          name="City"
          rules="required"
          v-slot="{ errors }"
        >
          <InputLabel :error="errors[0]" text="City" />
          <TRichSelect
            :options.sync="cities"
            valueAttribute="id"
            textAttribute="name"
            :placeholder.sync="getCityPlaceholder"
            :disabled="getCityDisabledState"
            :variant="{
              danger: !!errors[0],
              readonly: getCompanyDetailsDisabledState,
            }"
            v-model="form.city"
          />
          <InputErrorItem :message="errors[0]" />
        </ValidationProvider>
      </div>

      <TextInput
        v-model="form.zip_code"
        :rules="``"
        :label="`Zip Code`"
        :placeholder="`e.g. 5353`"
        :disabled="getCompanyDetailsDisabledState"
      />

      <ValidationProvider tag="div" name="Address" rules="" v-slot="{ errors }">
        <InputLabel :error="errors[0]" text="Address" />
        <TTextarea
          v-model="form.address"
          :rules="``"
          :disabled="getCompanyDetailsDisabledState"
          :variant="{
            readonly: getCompanyDetailsDisabledState,
          }"
        />
        <InputErrorItem :message="errors[0]" />
      </ValidationProvider>

      <div class="grid grid-cols-2 gap-4">
        <TextInput
          rules=""
          label="Registration ID"
          placeholder="e.g. 123 446 7890"
          v-model="form.registration_no"
        />

        <TextInput
          rules=""
          label="Tax ID"
          placeholder="e.g. TX123 456 7890"
          v-model="form.tax_id"
        />
      </div>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
// import { gmapApi } from 'vue2-google-maps'

import { useEndpoints } from '@/composables'
import { deepCompareObjects } from '@/utils'

import InputLabel from '@/components/form/InputLabel'
import TextInput from '@/components/form/TextInput'
import PlacesAutocomplete from '@/components/map/PlacesAutocomplete.vue'
import InputErrorItem from '@/components/form/InputErrorItem.vue'

import { LocationModal } from '@/components/modal'

export default {
  name: 'Step1',

  components: {
    InputLabel,
    TextInput,
    PlacesAutocomplete,
    InputErrorItem,
    LocationModal,
  },

  props: {
    organizations: {
      required: true,
    },
    countries: {
      required: true,
    },
    primaryKey: {
      type: null,
      required: false,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,

      form: {
        name: '',
        organization: '',

        warehouse_name: '',
        warehouse_lat: '',
        warehouse_lon: '',

        end_ride_picture: false,
        use_company_details: false,

        country: '',
        city: '',
        zip_code: '',
        address: '',
      },

      cities: [],
      cityIsLoading: false,
    }
  },

  computed: {
    // google: gmapApi,

    useCompanyDetails() {
      return this.form.use_company_details
    },

    country() {
      return this.form.country
    },

    getCountry: function() {
      return this.form.country || undefined
    },

    getOrganization: function() {
      return this.form.organization || undefined
    },

    getCityDisabledState: function() {
      if (!this.form.country) return true
      if (this.cityIsLoading) return true
      if (this.getCompanyDetailsDisabledState) return false
      return false
    },

    getCityPlaceholder() {
      if (!this.form.country) return 'Select a country first'
      if (this.cityIsLoading) return 'Loading ⟳'
      return 'Select a city'
    },

    getUseCompanyDetails: function() {
      return this.form.use_company_details
    },

    getCompanyDetailsDisabledState: function() {
      return !!this.getUseCompanyDetails
    },
  },

  watch: {
    // sync props.formData with $data.from
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          this.form = { ...data }
        }
      },
    },

    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        this.$emit('dirty', { step: 1, data: this.form })
      },
    },

    useCompanyDetails(c) {
      if (c === true) {
        this.form.country = this.$org.country.id

        // cities are asynchronously loaded after selecting country
        // seeding locally as it'll likely fail to load in such a short moment
        this.cities = [this.$org.city] // todo: need to use placeholder

        this.form.city = this.$org.city.id

        this.form.zip_code = this.$org.zip_code
        this.form.address = this.$org.address
      }
    },

    country: {
      immediate: true,
      deep: false,
      async handler(countryId) {
        // this.form.city =
        this.cityIsLoading = true
        this.cities = await this.$http
          .get(useEndpoints.dropdown.city(countryId))
          .then((res) => res.data.data)
          .catch((err) => console.log(err)) // toast?
          .finally(() => (this.cityIsLoading = false))
      },
    },
  },

  methods: {
    async submit() {
      this.$refs.submitButton.click()
    },

    async submitStep() {
      // if it's in add mode, user shouldn't be able to re submit step 1
      // or if the api supports it, it needs to be a patch request
      // if primaryKey is already set & mode is strict -> it means user is trying
      // to get back to step 1 & resubmit

      const method = this.isEditing ? 'PATCH' : 'POST'
      const url = this.isEditing
        ? useEndpoints.fleet.update(this.primaryKey)
        : useEndpoints.fleet.create()

      const formDataProxy = { ...this.form }
      const data = new FormData()
      for (const key in formDataProxy) {
        data.append(key, formDataProxy[key])
      }

      this.isLoading = true
      await this.$http({ method, url, data })
        .then((res) => {
          this.$emit('save', { step: 1, data: res.data })

          const message = `Fleet ${
            this.isEditing ? 'updated' : 'added'
          } successfully`

          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: 'Success',
            text: message,
          })
        })
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response.data.detail,
          })
        })
        .finally(() => (this.isLoading = false))
    },

    showGooglemapModal() {},

    onUpdatePlace(data) {
      console.log('onplace', data)
      this.form.warehouse_lat = data.geometry.location.lat()
      this.form.warehouse_lon = data.geometry.location.lng()
      this.form.warehouse_name = data.formatted_address
    },

    onSaveLocation({ marker, geocoder }) {
      this.form.warehouse_lat = marker.lat
      this.form.warehouse_lon = marker.lng
      this.form.warehouse_name =
        geocoder?.formatted_address || 'Unknown Location!'
    },
  },
}
</script>

<style lang="scss" scoped>
.map-btn {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: #ff598a;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
