<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form
      @submit.prevent="handleSubmit(submitStep)"
      enctype="multipart/form-data"
      class="sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-1"
    >
      <div class="grid items-center grid-cols-2 gap-4 my-3">
        <div class="flex items-center w-full gap-4">
          <upload-avatar
            v-model="form.profile_pic"
            :current="form.profile_pic"
            class=""
          />

          <!-- <ValidationProvider
            tag="div"
            class="w-full"
            name="Role Type"
            rules="required"
            v-slot="{ errors }"
          > -->
          <!-- <input-label :error="errors[0]" text="Role Type *" /> -->
          <AppInput
            type="richselect"
            name="Role Type"
            label="Role Type"
            rules="required"
            :options.sync="roles"
            valueAttribute="id"
            textAttribute="role_name"
            placeholder="Select role"
            :hide-search-box="true"
            v-model="form.role_id"
            :disabled="getDisableStatus"
          />
          <!-- <input-error-item :message="errors[0]" />
          </ValidationProvider> -->
        </div>

        <AppInput
          v-model="form.username"
          rules="required|min:4"
          name="Username"
          label="Username"
          placeholder="Username"
        />

        <!-- <ValidationProvider
          tag="div"
          name="Job Type"
          rules="required"
          v-slot="{ errors }"
        >
          <input-label :error="errors[0]" text="Job Type *" />
          <t-rich-select
            :options.sync="getJobTypes"
            :text-attribute="`text`"
            :value-attribute="`value`"
            :hide-search-box="true"
            :placeholder="`Select job type`"
            :variant="{ danger: !!errors[0] }"
            v-model="form.ranger_job_type"
          />
          <input-error-item :message="errors[0]" />
        </ValidationProvider> -->
      </div>

      <!-- <div class="mt-8">

      </div> -->

      <div class="grid grid-cols-2 gap-4 mt-8">
        <AppInput
          rules="required"
          label="Full Name"
          placeholder="e.g. Junior Martin"
          v-model="form.full_name"
        />

        <AppInput
          rules="required"
          label="Phone number"
          placeholder="e.g 01700000000"
          v-model="form.phone_number"
          type="tel"
          @country-changed="onCountryChanged"
        />
      </div>

      <div class="grid grid-cols-2 gap-4 mt-2">
        <AppInput
          placeholder="e.g. martin@gmail.com"
          rules="required"
          label="Email"
          v-model="form.email"
          type="email"
        />
        <AppInput
          type="date"
          placeholder="Birthday"
          rules="required"
          label="Date of Birth"
          :max="maxBirthDate"
          v-model="form.birthday"
        />
      </div>

      <div :class="['grid gap-4 mt-2', !isEditing && 'grid-cols-2']">
        <AppInput
          v-model="form.gender"
          :type="`richselect`"
          :label="`Gender`"
          :rules="`required`"
          :options="getGenders"
          :text-attribute="`text`"
          :value-attribute="`value`"
          :hide-search-box="true"
          :placeholder="`Select gender`"
        />

        <AppInput
          v-if="!isEditing"
          v-model="form.password"
          :type="'password'"
          :label="`Password`"
          :rules="`${isEditing ? '' : 'required|min:6'}`"
          :disabled="isEditing"
        />
      </div>

      <ValidationProvider
        tag="div"
        class="mt-2"
        name="AssignedFleets"
        rules="required"
        v-slot="{ errors }"
      >
        <input-label :error="errors[0]" text="Assigned Fleets *" />
        <TRichSelect
          multiple
          :close-on-select="false"
          :options="fleets"
          :text-attribute="`name`"
          :value-attribute="`id`"
          :hide-search-box="false"
          placeholder="Select fleets"
          :variant="{ danger: !!errors[0] }"
          v-model="form.assigned_fleets"
        />
        <input-error-item :message="errors[0]" />
      </ValidationProvider>

      <ValidationProvider
        tag="div"
        class="mt-2"
        name="PreferredCurrency"
        rules=""
        v-slot="{ errors }"
      >
        <input-label :error="errors[0]" text="Preferred Currency" />
        <TRichSelect
          :options="currencies"
          :text-attribute="`name`"
          :value-attribute="`id`"
          :hide-search-box="false"
          placeholder="Select currency"
          :variant="{ danger: !!errors[0] }"
          v-model="form.preferred_currency"
        />
        <input-error-item :message="errors[0]" />
      </ValidationProvider>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import PhoneNumber from 'awesome-phonenumber'
import { deepCompareObjects } from '@/utils'
import { OrganizationUserConfig } from '@/config/OrganizationUserConfig'

import UploadAvatar from '@/components/form/UploadAvatar'
import InputLabel from '@/components/form/InputLabel'
// import TextInput from '@/components/form/TextInput'

export default {
  name: 'Step1',
  components: {
    UploadAvatar,
    InputLabel,
    // TextInput,
  },
  props: {
    roles: {
      required: true,
    },
    fleets: {
      required: true,
    },
    currencies: {
      required: true,
    },
    userId: {
      type: null,
      required: false,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {
    this.maxDatecalculation()
  },
  data() {
    return {
      isLoading: false,
      form: {
        profile_pic: '',
        role_id: '',
        ranger_job_type: 'FULLTIME',
        full_name: '',
        username: '',
        country_code: '',
        phone_number: '',
        email: '',
        password: '',
        birthday: '',
        gender: '',
        assigned_fleets: [],
        preferred_currency: null,
      },

      show_password: false,
      selFleets: [],
      is_change_password: false,
      change_edit: false,
      error_exist_phone_number: '',
      maxBirthDate: '',
      phoneNoInfo: null,
    }
  },
  computed: {
    getJobTypes: () => [
      { text: 'Full Time', value: 'FULLTIME' },
      { text: 'Part Time', value: 'PARTTIME' },
    ],
    getGenders: () => [
      { text: 'Male', value: 'M' },
      { text: 'Female', value: 'F' },
    ],
    getDisableStatus() {
      return this.form.role_name === 'Owner'
    },
  },
  watch: {
    // sync props.formData with $data.from
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          this.form = { ...data }
        }
      },
    },
    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        this.$emit('dirty', { step: 1, data: this.form })
      },
    },
  },
  methods: {
    maxDatecalculation() {
      const date = new Date()
      const maxDate = date - 1000 * 60 * 60 * 24 * 4383 // current date's milliseconds - 1,000 ms * 60 s * 60 mins * 24 hrs * (# of days beyond one to go back)
      this.maxBirthDate = new Date(maxDate).toISOString().slice(0, 10)
    },
    onCountryChanged(e) {
      // alert(e.dialCode)
      console.log('phoneNoInfo', e)
      this.phoneNoInfo = e
    },

    saveCountryCode(countrycode) {
      this.form.country_code = countrycode
    },
    async submit() {
      this.$refs.submitButton.click()
    },
    async submitStep() {
      // if it's in add mode, user shouldn't be able to re submit step 1
      // or if the api supports it, it needs to be a patch request
      // if theUserId is already set & mode is strict -> it means user is trying
      // to get back to step 1 & resubmit
      const formDataProxy = { ...this.form }
      formDataProxy.ranger_job_type = 'FULLTIME'
      if (this.isEditing) delete formDataProxy.password

      const method = this.isEditing ? 'PATCH' : 'POST'
      const url = this.isEditing
        ? OrganizationUserConfig.api.update(this.userId)
        : OrganizationUserConfig.api.create

      if (
        formDataProxy.profile_pic == null ||
        typeof formDataProxy.profile_pic === 'string'
      ) {
        delete formDataProxy.profile_pic
      }

      let data = new FormData()

      if (formDataProxy.profile_pic)
        data.append('profile_pic', formDataProxy.profile_pic)

      data.append('role_id', formDataProxy.role_id)
      data.append('full_name', formDataProxy.full_name)

      const phoneNo = PhoneNumber(
        formDataProxy.phone_number,
        this.phoneNoInfo.iso2
      ).getNumber('e164')

      console.log(
        'phoneNo',
        formDataProxy.phone_number,
        this.form.phone_number,
        phoneNo
      )
      data.append('phone_number', phoneNo)

      data.append('email', formDataProxy.email)
      data.append('birthday', formDataProxy.birthday)
      data.append('gender', formDataProxy.gender)
      data.append('ranger_job_type', formDataProxy.ranger_job_type)
      formDataProxy.password && data.append('password', formDataProxy.password)

      if (formDataProxy?.assigned_fleets?.length) {
        formDataProxy.assigned_fleets.forEach((item) => {
          data.append('assigned_fleets', item)
        })
      }

      data.append('preferred_currency', formDataProxy.preferred_currency)
      data.append('username', formDataProxy.username)

      this.isLoading = true
      await this.$http({ method, url, data })
        .then((res) => {
          this.$emit('save', { step: 1, data: res.data })

          const message = `Organization User ${
            this.isEditing ? 'updated' : 'added'
          } successfully`

          this.$notify(
            {
              group: 'important',
              type: 'success',
              title: 'Success',
              text: message,
            },
            5000
          )
        })
        .catch((err) => {
          console.log('errorOccurred', err.response)
          // todo: emit error
          this.$notify(
            {
              group: 'important',
              type: 'error',
              title: 'Error occurred!',
              text: err.response.data.message ?? err.response.data,
            },
            12000
          )
          console.warn(err)
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>
