<template>
  <section>
    <AppFormLabel v-if="label" :text="label" :error="error" />

    <div
      :class="getWrapperClasses"
      @click="onClick"
      @dragover="onDragover"
      @dragleave="onDragleave"
      @drop="onDrop"
    >
      <input
        ref="file"
        type="file"
        class="overflow-hidden absolute w-0 h-0 opacity-0"
        accept="*"
        @change="onChange"
      />

      <div
        v-if="previewImage || current"
        key="upload-preview-or-placeholder"
        class="w-full h-full"
      >
        <img
          v-if="current && !previewImage"
          :src="current"
          class="object-cover overflow-hidden w-full h-full"
        />
        <img
          v-if="previewImage"
          :src="previewImage"
          class="object-cover overflow-hidden w-full h-full"
        />
      </div>

      <div
        v-else
        key="upload-preview-or-placeholder"
        class="grid place-items-center"
      >
        <span class="upload-text">{{ text }}</span>
        <button type="button" class="upload-button">{{ buttonText }}</button>
      </div>
    </div>

    <AppFormError v-if="label" :text="label" :error="error" />
  </section>
</template>

<script>
export default {
  name: 'UploadFileAlt',
  props: {
    value: {
      required: false,
    },
    current: {
      required: false,
    },

    label: {
      type: String,
      required: false,
      default: 'Upload File',
    },

    error: {
      type: [String, Boolean],
      required: false,
      default: '',
    },

    text: {
      type: String,
      required: false,
      default: 'Select or Drag files to upload',
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Upload',
    },
    variant: {
      requried: false,
      default: 'default',
    },
  },
  data() {
    return {
      file: null,
      previewImage: null,
    }
  },
  computed: {
    getWrapperClasses() {
      return 'upload-wrapper'
    },
  },
  methods: {
    onChange() {
      const input = this.$refs.file
      const file = input.files

      if (file && file[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },
    onClick() {
      this.$refs.file.click()
    },
    onDragover(event) {
      console.log('dragover = ', event)
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('onDragover')) {
        event.currentTarget.classList.add('onDragover')
      }
    },
    onDragleave(event) {
      // Clean up
      console.log('dragleave = ', event)
      event.currentTarget.classList.remove('onDragover')
      event.currentTarget.classList.add('onDragleave')
    },
    onDrop(event) {
      console.log('drop = ', event)
      event.preventDefault()

      this.$refs.file.files = event.dataTransfer.files
      // Trigger the onChange event manually
      this.onChange()

      event.currentTarget.classList.remove('onDragover')
      event.currentTarget.classList.remove('onDragleave')
      event.currentTarget.classList.add('onDrop')
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-wrapper {
  background: #f3f3f3;
  min-height: 160px;
  overflow: hidden;
  @apply relative rounded border-dotted border-2 border-gray-300 items-center justify-center flex cursor-pointer;
  @apply hover:bg-gray-100 hover:border-blue-300;

  &.onDragover {
    @apply bg-green-50 border-green-200;
  }

  &.onDragleave,
  &.onDrop {
    background: #f3f3f3;
  }

  .upload-button {
    min-width: 115px;
    height: 35px;
    border-radius: 3px;
    background-color: #e7e7e9;
    font-size: 13px;
    font-weight: bold;
    color: #454545;

    @apply px-2 hover:shadow-sm hover:bg-gray-200 outline-none appearance-none focus:outline-none focus:ring-0;
  }

  .upload-text {
    font-size: 13px;
    color: #87888e;
    text-align: center;
    margin-bottom: 10px;
  }
}
</style>
