export default {
  methods: {
    copyContentToClipboard(
      content,
      succeeded = {
        important: false,
        title: 'Copied',
        text: 'Successfully copied to your clipboard',
      }
    ) {
      const el = document.createElement('textarea')
      el.value = content
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-99999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.$notify(
        {
          group: succeeded.important ? 'modal' : 'generic',
          type: 'success',
          title: succeeded.title,
          text: succeeded.text,
        },
        2000
      )
    },
  },
}
