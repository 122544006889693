export const MarketplaceConfig = {
  api: {
    // get
    index: () => '/dashboard/marketplace/marketplace-fleet-list/',
    // post
    create: () => '/dashboard/marketplace/marketplace-fleet/',
    // get
    details: (marketplaceId) =>
      `/dashboard/marketplace/marketplace-fleet/${marketplaceId}/`,
    // patch
    update: (marketplaceId) =>
      `/dashboard/marketplace/marketplace-fleet/${marketplaceId}/`,
    // get
    purchaseIndex: () => '/dashboard/marketplace/purchases/',
    monthlyRevenueIndex: () => '/dashboard/marketplace/monthly-revenues/',
  },
  events: {
    name: 'marketplace',
    // refresh-index-data
    refresh: `rid-marketplace`,
    // slideover-right
    sorId: 'marketplace',
    sorOpen: 'sor-open-marketplace',
    sorClose: 'sor-close-marketplace',
    sorToggle: 'sor-toggle-marketplace',
    // editing-data
    editingData: 'edit-marketplace-data',
    // viewing-data
    viewingData: 'view-marketplace-data',
  },
}
