import apiConfig from './config'

export default {
  ...apiConfig,
  index: '/dashboard/roles/?dropdown',
  create: '/dashboard/roles/',
  update: (id = 'id') => {
    return `/dashboard/roles/${id}/`
  },
}
