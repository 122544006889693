export const FleetConfig = {
  api: {
    // get
    index: '/dashboard/fleets/',
    // get
    // post
    create: '/dashboard/fleets/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/fleets/${id}/`
    },
    single: (id = 'uuid') => {
      return `/dashboard/fleets/${id}/`
    },
    single_charging: (id = 'uuid') => {
      return `/dashboard/fleets/${id}/operator-task-charging-settings/`
    },
    single_rebalance: (id = 'uuid') => {
      return `/dashboard/fleets/${id}/operator-task-rebalancing-settings/`
    },
  },
  events: {
    name: 'fleet',
    // refresh-index-data
    refresh: `rid-fleet`,
    // slideover-right
    sorId: 'fleet',
    sorOpen: 'sor-open-fleet',
    sorClose: 'sor-close-fleet',
    sorToggle: 'sor-toggle-fleet',
    // editing-data
    editingData: 'edit-fleet-data',
    editingStep1Data: 'edit-fleet-step-1-data',
    editingStep2Data: 'edit-fleet-step-2-data',

    succeededStep1: 'succeeded-step-1',
    succeededStep2: 'succeeded-step-2',
    succeededStep3: 'successed-step-3',

    // viewing-data
    viewingData: 'view-fleet-data',
  },
}
