<template>
  <div>
    <div
      v-if="isOpen()"
      @click="close()"
      class="fixed w-full h-full bg-gray-100 opacity-0 cursor-default"
      :style="overlayStyle"
    ></div>
    <!-- <transition name="fade"> -->
    <div v-if="isOpen()" @click="close()">
      <slot v-bind:containerStyle="containerStyle">Dropdown Container</slot>
    </div>
    <!-- </transition> -->
  </div>
</template>

<script>
import { ZIndex } from '@/config/StyleConfig'

export default {
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      // events
      openingEvent: `xd-open-${this.id}`,
      closingEvent: `xd-close-${this.id}`,
      togglingEvent: `xd-toggle-${this.id}`,
      // state
      show: false,
    }
  },
  computed: {
    overlayStyle: function() {
      return `z-index: ${ZIndex.dropdown.overlay}`
    },
    containerStyle: function() {
      return `z-index: ${ZIndex.dropdown.container}`
    },
  },
  mounted: function() {
    this.handleEvents()
    // console.log(ZIndex.dropdown);
    // console.log(this.overlayStyle);
    // console.log(this.containerStyle);
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
    toggle() {
      this.show = !this.show
    },
    isOpen() {
      return this.show === true
    },
    // Call on Mounted
    handleEvents() {
      window.addEventListener(this.openingEvent, () => {
        this.open()
        //console.log(this.openingEvent + ' listened')
      })
      window.addEventListener(this.closingEvent, () => {
        this.close()
        //console.log(this.closingEvent + ' listened')
      })
      window.addEventListener(this.togglingEvent, () => {
        this.toggle()
        //console.log(this.togglingEvent + ' listened')
      })
    },
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
