<template>
  <div
    class="v-sidebar-menu"
    :class="sidebarClass"
    :style="[{ 'max-width': sidebarWidth }]"
    @mouseleave="onMouseLeave"
  >
    <!-- <slot name="header" /> -->
    <!-- <slot name="footer" /> -->
    <div class="menu-size-toggler">
      <button
        v-if="!hideToggle"
        class="w-full vsm--toggle-btn focus:outline-none toggle-primary"
        :class="{ 'vsm--toggle-btn_slot': $slots['toggle-icon'] }"
        :style="{ width: isCollapsed ? '100%' : '75px' }"
        @click="onToggleClick"
      >
        <svg
          class="w-6 h-6 ml-3 focus:outline-none"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h8m-8 6h16"
          ></path>
        </svg>
        <!-- <slot name="toggle-icon" /> -->
      </button>

      <div v-if="!isCollapsed" class="w-full toggle-secondary">
        <slot name="header" />
      </div>
    </div>

    <div
      class="vsm--scroll-wrapper SB-FarhanShares"
      :style="
        isCollapsed && [
          rtl ? { 'margin-left': '-11px' } : { 'margin-right': '-11px' },
        ]
      "
    >
      <div class="vsm--list" :style="isCollapsed && { width: widthCollapsed }">
        <sidebar-menu-item
          v-for="(item, index) in menu"
          :key="index"
          :item="item"
          :is-collapsed="isCollapsed"
          :active-show="activeShow"
          :show-one-child="showOneChild"
          :show-child="showChild"
          :rtl="rtl"
          :mobile-item="mobileItem"
          :disable-hover="disableHover"
          @set-mobile-item="setMobileItem"
          @unset-mobile-item="unsetMobileItem"
        >
          <slot slot="dropdown-icon" name="dropdown-icon" />
        </sidebar-menu-item>
      </div>
      <div
        v-if="isCollapsed"
        class="vsm--mobile-item"
        :style="mobileItemStyle.item"
      >
        <sidebar-menu-item
          v-if="mobileItem"
          :item="mobileItem"
          :is-mobile-item="true"
          :mobile-item-style="mobileItemStyle"
          :is-collapsed="isCollapsed"
          :show-child="showChild"
          :rtl="rtl"
        >
          <slot slot="dropdown-icon" name="dropdown-icon" />
        </sidebar-menu-item>
        <transition name="slide-animation">
          <div
            v-if="mobileItem"
            class="vsm--mobile-bg"
            :style="mobileItemStyle.background"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
// todo: move the generic scrollbar to another file,
// todo: create a specific scorllbar for the sidebar
import SidebarMenuItem from './SidebarMenuItem.vue'
import keymaster from 'keymaster'
export default {
  name: 'SidebarMenu',
  components: {
    SidebarMenuItem,
  },
  props: {
    menu: {
      type: Array,
      required: true,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '350px',
    },
    widthCollapsed: {
      type: String,
      default: '50px',
    },
    showChild: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: '',
    },
    showOneChild: {
      type: Boolean,
      default: false,
    },
    rtl: {
      type: Boolean,
      default: false,
    },
    relative: {
      type: Boolean,
      default: false,
    },
    hideToggle: {
      type: Boolean,
      default: false,
    },
    disableHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCollapsed: this.collapsed,
      mobileItem: null,
      mobileItemPos: 0,
      mobileItemHeight: 0,
      mobileItemTimeout: null,
      activeShow: null,
      parentHeight: 0,
      parentWidth: 0,
      parentOffsetTop: 0,
      parentOffsetLeft: 0,
    }
  },
  computed: {
    sidebarWidth() {
      return this.isCollapsed ? this.widthCollapsed : this.width
    },
    sidebarClass() {
      return [
        !this.isCollapsed ? 'vsm_expanded' : 'vsm_collapsed',
        this.theme ? `vsm_${this.theme}` : '',
        this.rtl ? 'vsm_rtl' : '',
        this.relative ? 'vsm_relative' : '',
      ]
    },
    mobileItemStyle() {
      return {
        item: [
          { position: 'absolute' },
          { top: `${this.mobileItemPos}px` },
          this.rtl ? { right: '0px' } : { left: '0px' },
          this.rtl
            ? { 'padding-right': this.sidebarWidth }
            : { 'padding-left': this.sidebarWidth },
          this.rtl && { direction: 'rtl' },
          { 'z-index': 0 },
          { width: `${this.parentWidth - this.parentOffsetLeft}px` },
          { 'max-width': this.width },
        ],
        dropdown: [
          { position: 'absolute' },
          { top: `${this.mobileItemHeight}px` },
          { width: '100%' },
          {
            'max-height': `${this.parentHeight -
              (this.mobileItemPos + this.mobileItemHeight) -
              this.parentOffsetTop}px`,
          },
          { 'overflow-y': 'auto' },
        ],
        background: [
          { position: 'absolute' },
          { top: '0px' },
          { left: '0px' },
          { right: '0px' },
          { width: '100%' },
          { height: `${this.mobileItemHeight}px` },
          { 'z-index': -1 },
        ],
      }
    },
  },
  watch: {
    collapsed(val) {
      if (this.isCollapsed === this.collapsed) return
      this.isCollapsed = val
      this.mobileItem = null
    },
  },
  methods: {
    onMouseLeave() {
      this.mobileItem = null
    },
    onToggleClick() {
      this.isCollapsed = !this.isCollapsed
      this.mobileItem = null
      this.$emit('toggle-collapse', this.isCollapsed)
      console.log('clicked')
    },
    onActiveShow(item) {
      this.activeShow = item
    },
    onItemClick(event, item, node) {
      this.$emit('item-click', event, item, node)
    },
    setMobileItem({ item, itemEl }) {
      if (this.mobileItem === item) return
      let sidebarTop = this.$el.getBoundingClientRect().top
      let itemTop = itemEl.getBoundingClientRect().top
      let itemLinkEl = itemEl.children[0]

      let styles = window.getComputedStyle(itemEl)
      let paddingTop = parseFloat(styles.paddingTop)
      let marginTop = parseFloat(styles.marginTop)

      let height = itemLinkEl.offsetHeight
      let positionTop = itemTop - sidebarTop + paddingTop + marginTop

      this.initParentOffsets()
      this.mobileItem = item
      this.mobileItemPos = positionTop
      this.mobileItemHeight = height
    },
    unsetMobileItem(immediate) {
      if (!this.mobileItem) return
      if (this.mobileItemTimeout) clearTimeout(this.mobileItemTimeout)
      if (immediate) {
        this.mobileItem = null
        return
      }
      this.mobileItemTimeout = setTimeout(() => {
        this.mobileItem = null
      }, 600)
    },
    initParentOffsets() {
      let {
        top: sidebarTop,
        left: sidebarLeft,
        right: sidebarRight,
      } = this.$el.getBoundingClientRect()
      let parent = this.relative
        ? this.$el.parentElement
        : document.documentElement
      this.parentHeight = parent.clientHeight
      this.parentWidth = parent.clientWidth
      if (this.relative) {
        let {
          top: parentTop,
          left: parentLeft,
        } = parent.getBoundingClientRect()
        this.parentOffsetTop = sidebarTop - (parentTop + parent.clientTop)
        this.parentOffsetLeft = this.rtl
          ? this.parentWidth - sidebarRight + (parentLeft + parent.clientLeft)
          : sidebarLeft - (parentLeft + parent.clientLeft)
      } else {
        this.parentOffsetTop = sidebarTop
        this.parentOffsetLeft = this.rtl
          ? this.parentWidth - sidebarRight
          : sidebarLeft
      }
    },
    onItemUpdate(newItem, item) {
      if (item === this.mobileItem) {
        this.mobileItem = newItem
      }
      if (item === this.activeShow) {
        this.activeShow = newItem
      }
    },
  },
  provide() {
    return {
      emitActiveShow: this.onActiveShow,
      emitItemClick: this.onItemClick,
      emitItemUpdate: this.onItemUpdate,
    }
  },
  mounted() {
    keymaster('⌘+m, ctrl+m', () => {
      this.onToggleClick()
    })
  },
}
</script>

<style lang="scss">
@import './scss/vue-sidebar-menu';
.responsive-toggle {
  z-index: 40;
  cursor: pointer;
}
.menu-size-toggler {
  display: flex;
  justify-items: center;
  // justify-content: space-between;
}
.toggle-primary {
  display: block;
  width: 55px;
  height: 55px;
}
.toggle-secondary {
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: 0 10px 0 20px;
  width: 100%;
  height: 55px;
}
</style>
