<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form
      @submit.prevent="handleSubmit(submitStep)"
      enctype="multipart/form-data"
      class="pt-6 md-max:pb-20 2xl:px-6 2xl:pb-3 sm:px-10"
    >
      <div>
        <h1 class="pb-2">Current Address</h1>
        <label class="flex items-center w-full mt-4 mb-4 cursor-pointer">
          <TCheckbox v-model="is_not_address_required" />

          <span
            class="ml-3 font-semibold select-none text-14p mt-px text-oBlack"
          >
            I want to skip this step
          </span>
        </label>
      </div>

      <div class="grid grid-cols-2 gap-4 mt-8">
        <AppInput
          name="Country"
          label="Country"
          :rules="!form.is_address_required ? '' : 'required'"
          :disabled="!form.is_address_required"
          :options.sync="countries"
          valueAttribute="id"
          textAttribute="name"
          placeholder="Select country"
          v-model="form.current_address_country"
          type="richselect"
        />

        <AppInput
          :rules="!form.is_address_required ? '' : 'required'"
          :disabled="!form.is_address_required"
          label="ZIP Code"
          placeholder="e.g. 53432"
          v-model="form.current_address_zip_code"
        />
      </div>

      <AppInput
        name="Address"
        label="Address"
        type="textarea"
        v-model="form.current_address_full"
        placeholder="2307 Oak Street, Old Forge, New York."
        :disabled="!form.is_address_required"
      />

      <h1 class="pt-6">Permanent Address</h1>

      <label class="flex items-center w-full mt-8 mb-4 cursor-pointer">
        <TCheckbox
          v-model="permanentAddressIsSame"
          :disabled="!form.is_address_required"
        />

        <span
          :class="[
            'ml-3 font-semibold select-none text-14p mt-px',
            !form.is_address_required ? 'text-gray-500' : 'text-oBlack',
          ]"
        >
          Same as current address
        </span>
      </label>

      <div class="grid grid-cols-2 gap-4 mt-8">
        <AppInput
          name="Country"
          label="Country"
          :rules="
            `${
              !form.is_address_required || permanentAddressIsSame
                ? ''
                : 'required'
            }`
          "
          :options.sync="countries"
          valueAttribute="id"
          textAttribute="name"
          placeholder="Select country"
          type="richselect"
          v-model="form.permanent_address_country"
          :disabled="!form.is_address_required || permanentAddressIsSame"
          :variant="{
            readonly: permanentAddressIsSame,
          }"
        />

        <AppInput
          :rules="
            `${
              !form.is_address_required || permanentAddressIsSame
                ? ''
                : 'required'
            }`
          "
          label="ZIP Code"
          placeholder="e.g 53432"
          :disabled="!form.is_address_required || permanentAddressIsSame"
          v-model="form.permanent_address_zip_code"
        />
      </div>

      <div>
        <AppInput
          name="Address"
          label="Address"
          :rules="
            `${
              !form.is_address_required || permanentAddressIsSame
                ? ''
                : 'required'
            }`
          "
          placeholder="2307 Oak Street, Old Forge, New York."
          v-model="form.permanent_address_full"
          :disabled="!form.is_address_required || permanentAddressIsSame"
          :variant="{ readonly: permanentAddressIsSame }"
        />
      </div>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { xMan, deepCompareObjects } from '@/utils'
import { OrganizationUserConfig } from '@/config/OrganizationUserConfig'

export default {
  name: 'AddEditOrganizationUserStep4',
  components: {},
  props: {
    userId: {
      required: true,
    },
    countries: {
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function() {
    return {
      isLoading: false,
      is_not_address_required: true,
      form: {
        // current address
        current_address_country: null,
        current_address_zip_code: '',
        current_address_full: '',
        // permanent adress
        permanent_address_country: null,
        permanent_address_zip_code: '',
        permanent_address_full: '',
        is_address_required: false,
      },
      permanentAddressIsSame: false,
    }
  },
  watch: {
    // sync props.formData with $data.from
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          this.form = { ...data }
          this.is_not_address_required = !data.is_address_required
        }
      },
    },
    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        // don't notify if editing data & user input data are same
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // otherwise notify whenever there's new input / change
        this.$emit('dirty', { step: 4, data: this.form })
      },
    },
    is_not_address_required: {
      immediate: true,
      deep: true,
      handler(data) {
        this.form.is_address_required = !data
      },
    },
    permanentAddressIsSame(updatedState) {
      if (updatedState) {
        this.form.permanent_address_country = this.form.current_address_country
        this.form.permanent_address_zip_code = this.form.current_address_zip_code
        this.form.permanent_address_full = this.form.current_address_full
      } else {
        this.form.permanent_address_country = ''
        this.form.permanent_address_zip_code = ''
        this.form.permanent_address_full = ''
      }
    },
  },
  methods: {
    async submit() {
      await this.$refs.submitButton.click()
    },
    async submitStep() {
      this.isLoading = true

      let url = OrganizationUserConfig.api.address_create(this.userId)
      let data = new xMan(this.form).toFormData()
      let method = 'PATCH'

      let message = this.isEditing
        ? 'User Address updated successfully'
        : 'User Address added successfully'

      await this.$http({ method, url, data })
        .then((res) => {
          this.$emit('save', { step: 4, data: res.data })

          this.$notify(
            {
              group: 'important',
              type: 'success',
              title: 'Success',
              text: message,
            },
            5000
          )
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'important',
              type: 'error',
              title: 'Error occurred!',
              text: err.response.data.message ?? err.response.data,
            },
            12000
          )
          console.warn(err)
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 22px;
  font-weight: bold;
  color: #2e2e39;
}
</style>
