<template lang="">
  <base-layout>
    <MarketPlaceAddEdit @add-edit="$store.dispatch('fsTable/fetchData')" />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus title="MarketPlace" @plus="add" :hide-plus="false" />
        </div>
      </div>

      <FSTable
        :fst-id="`marketplaceIndex`"
        :searchEnabled="false"
        :headers="tableHeaders"
        :endpoint="indexDataEndpoint"
      >
        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            text-fallback-always
          >
            <FSTableRowItem
              :text="item.id"
              :truncate="-5"
              :to="{
                name: 'ViewMarketplaceProfile',
                params: { id: item.id, redirectPath: path },
              }"
            />
            <FSTableRowItem class="flex items-center">
              <img
                :src="item.brand_image"
                class="w-8 h-8 rounded-full pr-4"
              />{{ item.brand_name }}
            </FSTableRowItem>
            <FSTableRowItem
              :text="item.fleet.name"
              :to="{
                name: 'ViewFleetProfile',
                params: { id: item.fleet.id, redirectPath: path },
              }"
            />
            <FSTableRowItem
              :text="getFormattedDateTime(item.order_start_date, 'D MMM, YYYY')"
            />
            <FSTableRowItem>
              {{ getFormattedDateTime(item.order_end_date, 'D MMM, YYYY') }}
              (<TimeAgo
                :title="item.order_end_date"
                :datetime="item.order_end_date"
                :auto-update="60"
              />)
            </FSTableRowItem>
            <FSTableRowItem :text="item.total_vehicle_number" />
            <FSTableRowItem>
              {{ item.fleet.country.currency_symbol }}
              {{ item.total_order_value }}</FSTableRowItem
            >

            <FSTableRowItem :text="item.total_purchased_vehicle" />
            <FSTableRowItem>
              {{ item.fleet.country.currency_symbol }}
              {{ item.total_purchased_amount }}</FSTableRowItem
            >
            <FSTableRowItem>
              <XStatus
                :text="item.order_status"
                :variant="getItemVariant(item.order_status)"
                profile="payment"
              />
            </FSTableRowItem>
            <FSTableRowItem
              ><div class="flex items-center">
                <oto-edit-icon @click="edit(item)" /></div
            ></FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
import { EventBus } from '@/utils'
import { useEndpoints } from '@/composables'
import MarketPlaceAddEdit from '@/views/marketplace/MarketPlaceAddEdit.vue'
import { getFormattedDateTime } from '@/utils/datetime'
import XStatus from '@/components/badge/XStatus'
export default {
  name: 'MarketplaceIndex',
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    MarketPlaceAddEdit,
    XStatus,
  },
  data() {
    return {
      indexDataEndpoint: MarketplaceConfig.api.index(),
      path: '',
      tableHeaders: [
        { text: 'ID', width: '5%', sort: 'id' },
        { text: 'Brand Name', width: '10%', sort: 'null' },
        { text: 'Assigned Fleet', width: '10%', sort: 'null' },
        { text: 'Order Start Date', width: '10%', sort: 'null' },
        { text: 'Order End Date', width: '10%', sort: 'null' },
        { text: 'Total Vehicle', width: '7%', sort: 'null' },
        { text: 'Total Order', width: '10%', sort: 'null' },
        { text: 'Total Purchased Vehicle', width: '10%', sort: 'null' },
        { text: 'Total Purchase Amount', width: '14%', sort: 'null' },
        { text: 'Order Status', width: '10%', sort: 'null' },
        { text: 'Actions', width: '10%', sort: 'null' },
      ],
      lockManufacturers: [],
      fleets: [],
    }
  },
  async created() {
    this.path = this.$route.path
    await this.$http
      .get(useEndpoints.manufacturers.index())
      .then((res) => {
        this.lockManufacturers = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          }
        })
      })
      .catch((e) => console.log(e))

    await this.$http
      .get(useEndpoints.dropdown.fleets())
      .then((res) => {
        this.fleets = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
            country: item.country,
          }
        })
        console.log('fleets-', this.fleets)
      })
      .catch((e) => console.log(e))
  },
  methods: {
    getFormattedDateTime,
    getItemVariant(data) {
      if (data === 'Completed') return 'green'
      if (data === 'Expired') return 'red'
      if (data === 'Pending') return 'orange'
      return 'purple'
    },
    add() {
      if (
        this.$store.getters['auth/organizationInfo'].subscription
          .marketplace_fleet_enabled
      ) {
        EventBus.$emit(MarketplaceConfig.events.editingData, {
          lockManufacturers: this.lockManufacturers,
          fleets: this.fleets,
          itemId: '',
        })
        dispatchEvent(new Event(MarketplaceConfig.events.sorToggle))
      } else {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: `Permission Denied`,
            text: 'You are not subscribed to access.Please contact with admin.',
          },
          7000
        )
      }
    },
    edit(item) {
      EventBus.$emit(MarketplaceConfig.events.editingData, {
        itemId: item.id,
        lockManufacturers: this.lockManufacturers,
        fleets: this.fleets,
      })
      dispatchEvent(new Event(MarketplaceConfig.events.sorToggle))
    },
  },
}
</script>
<style lang=""></style>
