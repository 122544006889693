<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <div class="grid grid-cols-2 gap-4">
        <ValidationProvider
          name="Is Parking Required"
          rules="required"
          v-slot="{ errors }"
        >
          <InputLabel :error="errors[0]" text="Is Parking Required *" />
          <TRichSelect
            v-model="form.is_parking_required"
            :options="getParkingRequiredOptions"
            :placeholder="`Select one`"
            :value-attribute="`value`"
            :text-attribute="`text`"
            :variant="{ danger: !!errors[0] }"
            hide-search-box
          />
          <InputErrorItem :message="errors[0]" />
        </ValidationProvider>

        <ValidationProvider
          name="Reservation Time"
          rules="required"
          v-slot="{ errors }"
        >
          <input-label :error="errors[0]" text="Fleet Reservation Time *" />
          <t-rich-select
            placeholder="Select one"
            :options="getFleetReservationTimeOptions"
            valueAttribute="value"
            textAttribute="text"
            :hideSearchBox="true"
            :variant="{ danger: !!errors[0] }"
            v-model="form.reservation_time"
          />
          <input-error-item :message="errors[0]" />
        </ValidationProvider>
      </div>

      <div class="grid grid-cols-2 gap-4">
        <ValidationProvider
          name="Off Ride Frequency"
          rules="required"
          v-slot="{ errors }"
        >
          <input-label
            :error="errors[0]"
            text="Location Update Frequency (On Ride) *"
          />
          <t-rich-select
            placeholder="Select one"
            :options="getFrequencyOptionsOnRide"
            :value-attribute="`value`"
            :text-attribute="`text`"
            :hideSearchBox="true"
            :variant="{ danger: !!errors[0] }"
            v-model="form.on_ride_location_update_frequency"
          />
          <input-error-item :message="errors[0]" />
        </ValidationProvider>

        <ValidationProvider
          name="On Ride Frequency"
          rules="required"
          v-slot="{ errors }"
        >
          <input-label
            :error="errors[0]"
            text="Location Update Frequency (Off Ride) *"
          />
          <t-rich-select
            placeholder="Select one"
            :options="getFrequencyOptionsOffRide"
            valueAttribute="value"
            textAttribute="text"
            :hideSearchBox="true"
            :variant="{ danger: !!errors[0] }"
            v-model="form.off_ride_location_update_frequency"
          />
          <input-error-item :message="errors[0]" />
        </ValidationProvider>
      </div>

      <div class="grid grid-cols-2 gap-4">
        <ValidationProvider
          name="Low Speed Limit (km/h)"
          rules="required"
          v-slot="{ errors }"
        >
          <input-label :error="errors[0]" text="Low Speed Limit (km/h)*" />
          <t-rich-select
            placeholder="Select one"
            :options="getSpeedLimitOptions"
            valueAttribute="value"
            textAttribute="text"
            :hideSearchBox="true"
            :variant="{ danger: !!errors[0] }"
            v-model="form.low_speed_limit"
          />
          <input-error-item :message="errors[0]" />
        </ValidationProvider>

        <ValidationProvider
          name="Medium Speed Limit (km/h)"
          rules="required"
          v-slot="{ errors }"
        >
          <input-label :error="errors[0]" text="Medium Speed Limit (km/h)*" />
          <t-rich-select
            placeholder="Select one"
            :options="getSpeedLimitOptions"
            valueAttribute="value"
            textAttribute="text"
            :hideSearchBox="true"
            :variant="{ danger: !!errors[0] }"
            v-model="form.medium_speed_limit"
          />
          <input-error-item :message="errors[0]" />
        </ValidationProvider>
      </div>

      <div class="grid grid-cols-2 gap-4">
        <ValidationProvider
          name="High Speed Limit (km/h)"
          rules="required"
          v-slot="{ errors }"
        >
          <input-label :error="errors[0]" text="High Speed Limit (km/h)*" />
          <t-rich-select
            placeholder="Select one"
            :options="getSpeedLimitOptions"
            valueAttribute="value"
            textAttribute="text"
            :hideSearchBox="true"
            :variant="{ danger: !!errors[0] }"
            v-model="form.high_speed_limit"
          />
          <input-error-item :message="errors[0]" />
        </ValidationProvider>

        <ValidationProvider
          name="Max Speed Mode"
          rules="required"
          v-slot="{ errors }"
        >
          <input-label :error="errors[0]" text="Max Speed Mode*" />
          <t-rich-select
            placeholder="Select one"
            :options="getMaxSpeedLimitOptions"
            valueAttribute="value"
            textAttribute="text"
            :hideSearchBox="true"
            :variant="{ danger: !!errors[0] }"
            v-model="form.max_speed_limit"
          />
          <input-error-item :message="errors[0]" />
        </ValidationProvider>
      </div>

      <div class="grid grid-cols-2 gap-4">
        <ValidationProvider
          name="Fleet visibility"
          rules="required"
          v-slot="{ errors }"
        >
          <input-label :error="errors[0]" text="Fleet Visibility *" />
          <t-rich-select
            placeholder="Select one"
            :options="getVisibilityOptions"
            valueAttribute="value"
            textAttribute="text"
            :hideSearchBox="true"
            :variant="{ danger: !!errors[0] }"
            v-model="form.visibility"
          />
          <input-error-item :message="errors[0]" />
        </ValidationProvider>

        <ValidationProvider
          name="Is Pause Ride Enabled"
          rules="required"
          v-slot="{ errors }"
        >
          <InputLabel :error="errors[0]" text="Is Pause Ride Enabled *" />
          <TRichSelect
            v-model="form.pause_ride_enabled"
            :options="getPauseEnabledOptions"
            :placeholder="`Select one`"
            :value-attribute="`value`"
            :text-attribute="`text`"
            :variant="{ danger: !!errors[0] }"
            hide-search-box
          />
          <InputErrorItem :message="errors[0]" />
        </ValidationProvider>
      </div>
      <ValidationProvider
        name="Sign Up Bonus"
        rules="required"
        v-slot="{ errors }"
      >
        <InputLabel :error="errors[0]" text="Sign Up Bonus *" />
        <text-input
          v-model="form.user_init_balance"
          rules=""
          placeholder=""
          :disabled="false"
        />
        <InputErrorItem :message="errors[0]" />
      </ValidationProvider>
      <div class="grid grid-cols-2 gap-4">
        <ValidationProvider
          name="Inviter Bonus Amount"
          rules="required"
          v-slot="{ errors }"
        >
          <InputLabel :error="errors[0]" text="Inviter Bonus *" />
          <text-input
            v-model="form.invitation_amount"
            rules=""
            placeholder=""
            :disabled="false"
          />
          <InputErrorItem :message="errors[0]" />
        </ValidationProvider>

        <ValidationProvider
          name="Invitee Bonus Amount"
          rules="required"
          v-slot="{ errors }"
        >
          <InputLabel :error="errors[0]" text="Invitee Bonus *" />
          <text-input
            v-model="form.invitee_invitation_amount"
            rules=""
            placeholder=""
            :disabled="false"
          />
          <InputErrorItem :message="errors[0]" />
        </ValidationProvider>
      </div>

      <ValidationProvider
        name="Number of Trips Required for Referral Bonus"
        rules="required"
        v-slot="{ errors }"
      >
        <InputLabel
          :error="errors[0]"
          text="Number of Trips Required for Referral Bonus *"
        />
        <text-input
          v-model="form.invitation_required_trips"
          rules=""
          placeholder=""
          :disabled="false"
        />
        <InputErrorItem :message="errors[0]" />
      </ValidationProvider>

      <!-- end 3 -->
      <!-- penalty point -->
      <section>
        <div class="flex items-center my-5 text-sm font-bold text-gray-500">
          <span>Manage Penalty By Amount</span>
          <div
            class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
            @click="onAddPenaltyTypeField"
          >
            <i class="fas fa-plus-circle" style="color: black"></i>
          </div>
        </div>
        <!-- {{ penalty Types }} -->
        <div
          class="flex items-center"
          v-for="(item, index) in selectedPenaltyTypes"
          :key="index"
        >
          <div class="flex gap-2 items-center w-11/12">
            <div class="w-1/2">
              <AppInput
                v-model="item.id"
                type="richselect"
                :name="`Penalty Type ${index}`"
                label="Type"
                value-attribute="id"
                text-attribute="title"
                placeholder="Select Penalty Type"
                :options="penaltyOptions"
                hide-search-box
              />
            </div>

            <div class="w-1/2">
              <AppInput
                v-model="item.deduct_amount"
                type="number"
                :name="
                  `amount
              ${index}`
                "
                rules=""
                :label="`Amount (${form.currency_symbol})`"
                placeholder="Write an amount"
              />
            </div>
          </div>

          <div class="flex justify-center w-1/12 item-center">
            <div
              class="col-span-1 cursor-pointer focus:text-gray-400"
              @click="onRemovePenaltyTypeField(index)"
            >
              <i class="fas fa-minus-circle" style="color: #d90a20"></i>
            </div>
          </div>
        </div>
      </section>
      <!-- /penalty point -->

      <template v-if="fleetIsPrivate">
        <div class="grid grid-cols-2 gap-4">
          <text-input
            rules="required"
            label="Private Code"
            placeholder="Private Code"
            v-model="form.private_code"
          />

          <ValidationProvider
            name="Private Code Refresh Time"
            rules="required"
            v-slot="{ errors }"
          >
            <input-label
              :error="errors[0]"
              text="Private Code Refresh Time *"
            />
            <t-rich-select
              placeholder="Select one"
              :options="getFleetReservationTimeOptions"
              valueAttribute="value"
              textAttribute="text"
              :hideSearchBox="true"
              :variant="{ danger: !!errors[0] }"
              v-model="form.private_code_refresh_mins"
            />
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
      </template>

      <div class="my-4 parentbox">
        <div
          v-if="!isOrgEmailSettingsOkay"
          class="childbox"
          @click.once="showErrorMsg"
        ></div>
        <TCheckbox
          v-model="form.email_enabled"
          :label="`Use Organization Email Configuration`"
          :name="`Use Organization Email Configuration`"
          :disabled="!isOrgEmailSettingsOkay"
          wrapped
        />

        <small v-show="showMsg" class="text-xs text-red-600">
          You haven't set default organization email settings
          <span class="font-bold">{{
            `(email ${emailMissingSettings.join(',')})`
          }}</span>
          properly . Please update from
          <router-link
            :to="{ name: 'EmailSettings' }"
            :target="`_blank`"
            :class="`text-blue-800 `"
          >
            here
          </router-link>
          .
        </small>
      </div>

      <text-input
        v-model="form.email_address"
        rules=""
        label="Email Address"
        placeholder="john_doe@gmail.com"
        :disabled="form.email_enabled"
      />

      <TCheckbox
        v-model="form.email_is_smtp"
        :label="`Email Is SMTP`"
        :name="`Email Is SMTP`"
        :disabled="form.email_enabled"
        wrapped
      />

      <template v-if="emailIsSMTP">
        <div class="grid grid-cols-2 gap-4">
          <text-input
            rules=""
            label="Email Host"
            placeholder="smtp.gmail.com"
            v-model="form.email_host"
            :disabled="form.email_enabled"
          />
          <text-input
            rules=""
            label="Email Port"
            placeholder="587"
            v-model="form.email_port"
            :disabled="form.email_enabled"
          />
        </div>

        <div class="grid grid-cols-2 gap-4">
          <text-input
            rules=""
            label="Email Passoword"
            placeholder="*** *** ***"
            type="password"
            v-model="form.email_password"
            :disabled="form.email_enabled"
          />

          <ValidationProvider
            name="Email Encryption"
            rules=""
            v-slot="{ errors }"
          >
            <input-label :error="errors[0]" text="Email Encryption" />
            <t-rich-select
              placeholder="Select one"
              :options="getEmailEncryptionOptions"
              valueAttribute="value"
              textAttribute="text"
              :hideSearchBox="true"
              :variant="{
                danger: !!errors[0],
                readonly: false,
              }"
              :disabled="form.email_enabled"
              :class="form.email_enabled ? `text-gray-500` : ''"
              v-model="form.email_encryption"
            />
            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
      </template>
      <template v-else>
        <div class="flex items-center">
          <div class="w-full">
            <text-input
              rules=""
              label="Email API Key"
              placeholder="e.g. xvgsf^$R7865rtxsnb"
              v-model="form.email_api_key"
              :disabled="form.email_enabled"
            />
          </div>
        </div>
      </template>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { deepCompareObjects } from '@/utils'
import { useEndpoints } from '@/composables'

export default {
  name: 'Step2',

  components: {
    InputLabel: () => import('@/components/form/InputLabel'),
    TextInput: () => import('@/components/form/TextInput'),
  },

  props: {
    primaryKey: {
      required: true,
    },
    orgEmailSettings: {
      type: Object,
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      saveCounter: 0,
      showMsg: false,
      emailMissingSettings: [],
      penaltyOptions: [],
      allPenaltyTypes: [],
      selectedPenaltyTypes: [],
      form: {
        id: '',
        currency_symbol: '',
        is_parking_required: '',
        reservation_time: '',
        pause_ride_enabled: '',
        user_init_balance: '',
        invitation_amount: '',
        invitee_invitation_amount: '',
        invitation_required_trips: '',
        on_ride_location_update_frequency: '',
        off_ride_location_update_frequency: '',

        max_speed_limit: '',
        low_speed_limit: null,
        medium_speed_limit: null,
        high_speed_limit: null,
        visibility: '',

        private_code: '',
        private_code_refresh_mins: '',

        email_enabled: '',
        email_address: '',

        email_api_key: '',

        email_is_smtp: '',
        email_host: '',
        email_port: '',
        email_password: '',
        email_encryption: '',
      },
      previousEmailSettings: {
        email_address: '',
        email_api_key: '',
        email_is_smtp: '',
        email_host: '',
        email_port: '',
        email_password: '',
        email_encryption: '',
      },
    }
  },

  computed: {
    // getPenaltyOptions() {
    //   return this.penaltyOptions.filter(
    //     (pE) => !this.selectedPenaltyTypes.some((sE) => pE.id === sE.id)
    //   )
    // },
    isOrgEmailSettingsOkay() {
      const s = this.orgEmailSettings
      // check if all settings item has a value
      let flag = Object.keys(s).length !== 0

      for (const k in s) {
        if (s[k] === 'null' || s[k] === '' || s[k] === null) {
          flag = false
          let name
          if (k === 'email_is_smtp') {
            name = 'SMTP decision'
          } else if (k === 'email_api_key') {
            name = 'API key'
          } else {
            name = k.split('_')[1]
          }
          this.emailMissingData(name)
        }
      }
      return flag
    },

    emailEnabled() {
      return this.form.email_enabled
    },

    emailIsSMTP() {
      return this.form.email_is_smtp
    },

    fleetIsPrivate() {
      return this.form.visibility === 'PR'
    },

    getParkingRequiredOptions() {
      return [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ]
    },
    getPauseEnabledOptions() {
      return [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ]
    },

    getVisibilityOptions() {
      return [
        { value: 'PU', text: 'Public' },
        { value: 'PR', text: 'Private' },
      ]
      // ("PU", "Public"),
      // ("PR", "Private"),
      // ("KP", "Keep")
    },

    getFleetReservationTimeOptions() {
      return [
        { value: 5, text: '5 Min' },
        { value: 10, text: '10 Min' },
        { value: 15, text: '15 Min' },
        { value: 20, text: '20 Min' },
        { value: 25, text: '25 Min' },
        { value: 30, text: '30 Min' },
      ]
    },

    getMaxSpeedLimitOptions() {
      return [
        { value: 'LOW', text: 'Low' },
        { value: 'MEDIUM', text: 'Medium' },
        { value: 'HIGH', text: 'High' },
      ]
    },
    getSpeedLimitOptions() {
      return [
        { value: 6, text: 6 },
        { value: 7, text: 7 },
        { value: 8, text: 8 },
        { value: 9, text: 9 },
        { value: 10, text: 10 },
        { value: 11, text: 11 },
        { value: 12, text: 12 },
        { value: 13, text: 13 },
        { value: 14, text: 14 },
        { value: 15, text: 15 },
        { value: 16, text: 16 },
        { value: 17, text: 17 },
        { value: 18, text: 18 },
        { value: 19, text: 19 },
        { value: 20, text: 20 },
        { value: 21, text: 21 },
        { value: 22, text: 22 },
        { value: 23, text: 23 },
        { value: 24, text: 24 },
        { value: 25, text: 25 },
      ]
    },

    getFrequencyOptionsOnRide() {
      return [
        { value: 10, text: '10s' },
        { value: 20, text: '20s' },
        { value: 30, text: '30s' },
        { value: 60, text: '1m' },
        { value: 90, text: '1m 30s' },
        { value: 120, text: '2m' },
        { value: 150, text: '2m 30s' },
        { value: 180, text: '3m' },
        { value: 210, text: '3m 30s' },
        { value: 240, text: '4m' },
      ]
    },
    getFrequencyOptionsOffRide() {
      return [
        { value: 30, text: '30s' },
        { value: 60, text: '1m' },
        { value: 90, text: '1m 30s' },
        { value: 120, text: '2m' },
        { value: 150, text: '2m 30s' },
        { value: 180, text: '3m' },
        { value: 210, text: '3m 30s' },
        { value: 240, text: '4m' },
      ]
    },

    getEmailEncryptionOptions() {
      return [
        { value: 'TLS', text: 'TLS' },
        { value: 'SSL', text: 'SSL' },
        { value: 'None', text: 'None' },
      ]
    },
  },

  watch: {
    // sync props.formData with $data.from
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          if (!data.email_enabled) {
            data.email_enabled = true
          } else {
            data.email_enabled = false
          }
          this.form = { ...data }
          this.previousEmailSettings.email_address = data.email_address
          this.previousEmailSettings.email_api_key = data.email_api_key
          this.previousEmailSettings.email_is_smtp = data.email_is_smtp
          this.previousEmailSettings.email_host = data.email_host
          this.previousEmailSettings.email_port = data.email_port
          this.previousEmailSettings.email_password = data.email_password
          this.previousEmailSettings.email_encryption = data.email_encryption
        }
      },
    },
    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        // don't notify if editing data & user input data are same
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // otherwise notify whenever there's new input / change
        this.$emit('dirty', { step: 2, data: this.form })
      },
    },

    emailEnabled: {
      immediate: false,
      deep: false,
      handler(val) {
        if (this.saveCounter === 0) {
          if (val) {
            const s = this.orgEmailSettings
            this.form.email_address = s.email_address
            this.form.email_api_key = s.email_api_key
            this.form.email_is_smtp = s.email_is_smtp
            this.form.email_host = s.email_host
            this.form.email_port = s.email_port
            this.form.email_password = s.email_password
            this.form.email_encryption = s.email_encryption
          } else {
            this.form.email_address = this.previousEmailSettings.email_address
            this.form.email_api_key = this.previousEmailSettings.email_api_key
            this.form.email_is_smtp = this.previousEmailSettings.email_is_smtp
            this.form.email_host = this.previousEmailSettings.email_host
            this.form.email_port = this.previousEmailSettings.email_port
            this.form.email_password = this.previousEmailSettings.email_password
            this.form.email_encryption = this.previousEmailSettings.email_encryption
          }
        }
      },
    },
  },
  created() {
    this.saveCounter = 0

    this.$http
      .get(useEndpoints.fleet.penaltyIndex(this.form.id))
      .then((res) => {
        this.allPenaltyTypes = res.data.data
        this.selectedPenaltyTypes = res.data.data.filter(
          (item) => parseFloat(item.deduct_amount) > 0
        )
        this.penaltyOptions = res.data.data.map((item) => {
          return { id: item.id, title: item.title }
        })
      })
  },
  methods: {
    onAddPenaltyTypeField() {
      if (this.selectedPenaltyTypes.length < this.allPenaltyTypes.length) {
        this.selectedPenaltyTypes.unshift({ id: '', deduct_amount: '0.00' })
      } else {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: 'CAUTION',
            text: 'No more policy have been left',
          },
          3000
        )
      }
    },
    onRemovePenaltyTypeField(index) {
      this.selectedPenaltyTypes.splice(index, 1)
    },
    showErrorMsg() {
      this.showMsg = true
    },
    emailMissingData(name) {
      this.emailMissingSettings.push(name)
    },
    async submit() {
      this.$refs.submitButton.click()
    },
    async submitStep() {
      const method = 'PATCH'
      if (this.selectedPenaltyTypes.length) {
        //selected
        this.selectedPenaltyTypes.forEach((element) => {
          const penaltyUrl = useEndpoints.fleet.penaltiesUpdate(element.id)
          const penaltyData = new FormData()
          penaltyData.append('deduct_amount', element.deduct_amount)
          this.$http({
            url: penaltyUrl,
            method: 'PATCH',
            data: penaltyData,
          })
        })

        //another
        const unselectedPenaltyTypes = this.allPenaltyTypes.filter(
          (aE) => !this.selectedPenaltyTypes.some((sE) => aE.id === sE.id)
        )
        unselectedPenaltyTypes.forEach((element) => {
          const penaltyUrl = useEndpoints.fleet.penaltiesUpdate(element.id)
          const penaltyData = new FormData()
          penaltyData.append('deduct_amount', 0)
          this.$http({
            url: penaltyUrl,
            method: 'PATCH',
            data: penaltyData,
          })
        })
      } else {
        this.allPenaltyTypes.forEach((element) => {
          const penaltyUrl = useEndpoints.fleet.penaltiesUpdate(element.id)
          const penaltyData = new FormData()
          penaltyData.append('deduct_amount', 0)
          this.$http({
            url: penaltyUrl,
            method: 'PATCH',
            data: penaltyData,
          })
        })
      }
      const url = useEndpoints.fleet.update(this.primaryKey)
      if (!this.form.email_enabled) {
        this.form.email_enabled = true
      } else {
        this.form.email_enabled = false
      }

      const formDataProxy = { ...this.form }
      // delete optional fields based on context
      if (this.emailIsSMTP) {
        delete formDataProxy.email_api_key
      } else {
        delete formDataProxy.email_host
        delete formDataProxy.email_port
        delete formDataProxy.email_password
        delete formDataProxy.email_encryption
      }

      // delete optional fields based on context
      if (this.fleetIsPrivate === false) {
        delete formDataProxy.private_code
        delete formDataProxy.private_code_refresh_mins
      }

      const data = new FormData()
      for (const key in formDataProxy) {
        data.append(key, formDataProxy[key])
      }
      //---start
      this.saveCounter++
      this.form.email_enabled = !this.form.email_enabled //just for matching UI sense
      //---end
      this.isLoading = true
      await this.$http({ method, url, data })
        .then((res) => {
          this.$emit('save', { step: 2, data: res.data })

          const message = `Organization User ${
            this.isEditing ? 'updated' : 'added'
          } successfully`

          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: 'Success',
            text: message,
          })
        })
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response.status,
          })
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 22px;
  font-weight: bold;
  color: #2e2e39;
}
.parentbox {
  position: relative;
}
.childbox {
  position: absolute;
  width: 1rem;
  height: 100%;
}
</style>
