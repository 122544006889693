var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:"pt-6 sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-10",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitStep)}}},[_c('div',[_c('h1',{staticClass:"pb-2"},[_vm._v("Personal Identification")]),_c('label',{staticClass:"flex items-center w-full mt-4 mb-4 cursor-pointer"},[_c('TCheckbox',{model:{value:(_vm.is_not_document_required),callback:function ($$v) {_vm.is_not_document_required=$$v},expression:"is_not_document_required"}}),_c('span',{staticClass:"ml-3 font-semibold select-none text-14p mt-px text-oBlack"},[_vm._v(" I want to skip this step ")])],1)]),_c('div',{staticClass:"flex items-center pt-8"},[_c('AppInput',{attrs:{"rules":!_vm.form.is_document_required ? '' : 'required',"disabled":!_vm.form.is_document_required,"label":"NID Number","placeholder":"e.g. 92344563432"},model:{value:(_vm.form.nid_number),callback:function ($$v) {_vm.$set(_vm.form, "nid_number", $$v)},expression:"form.nid_number"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-8 "},[_c('div',[_c('ValidationProvider',{staticClass:"mt-2",attrs:{"tag":"div","name":"NID Front side","rules":!_vm.form.is_document_required ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":("NID Front Side " + (!_vm.form.is_document_required ? '' : '*'))}}),_c('UploadImageAlt',{staticClass:"max-h-14",attrs:{"current":_vm.form.nid_front_side_pic,"text":"Drag and drop the front side photo of NID","button-text":"Upload Front Side"},model:{value:(_vm.form.nid_front_side_pic),callback:function ($$v) {_vm.$set(_vm.form, "nid_front_side_pic", $$v)},expression:"form.nid_front_side_pic"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{staticClass:"mt-2",attrs:{"tag":"div","name":"NID Front side","rules":!_vm.form.is_document_required ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":("NID Back Side " + (!_vm.form.is_document_required ? '' : '*'))}}),_c('UploadImageAlt',{staticClass:"max-h-14",attrs:{"current":_vm.form.nid_back_side_pic,"text":"Drag and drop the back side photo of NID","button-text":"Upload Back Side"},model:{value:(_vm.form.nid_back_side_pic),callback:function ($$v) {_vm.$set(_vm.form, "nid_back_side_pic", $$v)},expression:"form.nid_back_side_pic"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)]),_c('div',[_c('label',{staticClass:"flex items-center w-full mt-4 mb-4 cursor-pointer"},[_c('TCheckbox',{attrs:{"disabled":_vm.is_not_document_required},model:{value:(_vm.form.is_verified),callback:function ($$v) {_vm.$set(_vm.form, "is_verified", $$v)},expression:"form.is_verified"}}),_c('span',{class:[
            'ml-3 font-semibold select-none text-14p mt-px',
            _vm.is_not_document_required ? 'text-gray-500' : 'text-oBlack' ]},[_vm._v(" Verified ")])],1)]),_c('h1',{staticClass:"pt-12"},[_vm._v("Driving License (optional)")]),_c('div',{staticClass:"mt-6"},[_c('AppInput',{attrs:{"name":"Driving License","label":"Driving License","options":_vm.getLicenseOptions,"text-attribute":"text","value-attribute":"value","hide-search-box":true,"disabled":!_vm.form.is_document_required},model:{value:(_vm.form.has_driving_license),callback:function ($$v) {_vm.$set(_vm.form, "has_driving_license", $$v)},expression:"form.has_driving_license"}})],1),(false)?_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-8"},[_c('div',[_c('input-label',{attrs:{"error":"","text":"Driving License Front Side"}}),_c('upload-image',{attrs:{"type":"w-full","idx":0,"current":_vm.form.nid_front_side_pic,"title":"Drag and droff front side","disabled":_vm.is_not_document_required},on:{"input":function($event){return _vm.notifyDirty(true)}}})],1),_c('div',[_c('input-label',{attrs:{"error":"","text":"Driving License Back Side"}}),_c('upload-image',{attrs:{"type":"w-full","idx":1,"current":_vm.form.nid_back_side_pic,"title":"Drag and droff back side","disabled":_vm.is_not_document_required},on:{"input":function($event){return _vm.notifyDirty(true)}}})],1)]):_vm._e(),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }