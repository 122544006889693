<template>
  <TModal
    ref="popup"
    :name="modalId"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    :escToClose="false"
    :clickToClose="false"
    :fixedClasses="{
      overlay: 'm-overlay',
      wrapper: 'm-wrapper',
      modal: 'm-modal',
    }"
    :classes="{
      body: 'm-body',
    }"
  >
    <div class="border-b x-container">
      <div class="x-left">
        <img :src="getAvatarUrl" class="object-contain w-24 h-24 rounded-md" />
      </div>

      <div class="x-right">
        <section class="text-lg tracking-wide text-gray-500">
          Welcome {{ $user.full_name }}
        </section>

        <section class="text-2xl font-medium text-oBlack">
          Let's complete organization <br />
          profile setup
        </section>
      </div>
    </div>

    <section>
      <div class="flex justify-center items-center mt-2">
        <img
          class="h-44 shadow-md"
          src="@/assets/placeholder/add-credit-card.svg"
          alt=""
        />
      </div>

      <div class="mx-auto mt-2 max-w-sm text-sm text-center text-gray-500">
        To use admin control panel you have to add your valid credit card
        information. If you input invalid credit card information then your
        account will be deactivated. Your monthly subscription feee will be
        deducted from this credit card.
      </div>

      <div class="flex justify-center items-center mt-2">
        <AppButton
          :isLoading="isLoading"
          text="Add Card"
          variant="blue"
          width="130px"
          rounded="none"
          class="mt-5"
          @click="stripeCheckout"
        />
      </div>
    </section>

    <iframe ref="iframeRef" class="hidden" />

    <!-- <div class="flex gap-6 pl-36 mt-3 w-full border-b border-gray-300">
      <div
        class="x-tab-item"
        :class="{ 'x-tab-active': isTabActive('credential-setup') }"
        @click="onChangeTab('credential-setup')"
      >
        Credential Setup
      </div>
      <div
        class="x-tab-item"
        :class="{ 'x-tab-active': isTabActive('payment-setup') }"
        @click="onChangeTab('payment-setup')"
      >
        Payment Setup
      </div>
    </div>

    <div class="mt-3 x-container">
      <div class="x-left" />
      <div
        class="grid overflow-y-auto grid-cols-3 gap-4 sb-farhan x-right"
        style="max-height: 300px;"
      >
        <template v-if="isTabActive('credential-setup')">
          <div>
            <AppInput type="password" label="Password" name="password" />
            <AppInput
              type="password"
              label="Confirm Password"
              name="password_confirmation"
            />
            <div class="flex justify-end"><AppButton text="Next" /></div>
          </div>

          <div class="x-notice">
            <i class="text-xl fas fa-lock" />
            <p>
              You need this for the first time to ensure your login credential
              is secured.
            </p>
          </div>
        </template>

        <template v-if="isTabActive('payment-setup')">
          <div>
            <AppInput type="text" label="Name On Card" />
            <AppInput type="number" label="Card Number" />
            <div class="grid grid-cols-2 gap-4">
              <AppInput type="number" label="Valid Through" />
              <AppInput type="number" label="CVV" />
            </div>
            <AppInput type="richselect" label="Country" />

            <div class="flex justify-end">
              <AppButton text="Save" />
            </div>
          </div>

          <div class="x-notice green">
            <i class="text-xl fas fa-credit-card" />
            <p>
              The card will used to auto-renew your subscription. You can add
              more card as backup.
            </p>
          </div>
        </template>
      </div>
    </div> -->
  </TModal>
</template>

<script>
import { useEndpoints } from '@/composables'
import { httpClient } from '@/services'
export default {
  name: 'CreditCardModal',
  components: {},

  props: {
    modalId: {
      type: [String, Number],
      default: 'CreditCardModal',
    },
    icon: {
      type: [String, Number],
      required: false,
      default: 'fas fa-exclamation-circle',
    },
    title: {
      type: [String, Number],
      required: false,
      default: 'Are you sure?',
    },
    subtitle: {
      type: [String, Number],
      required: false,
      default: 'This action cannot be undone.',
    },
    busy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      tabs: [
        {
          id: 'credential-setup',
          name: 'Credential Setup',
          icon: 'far fa-user',
        },
        {
          id: 'payment-setup',
          name: 'Payment Setup',
          icon: 'fas fa-credit-card',
        },
      ],
      selectedTab: 'credential-setup',
      htmlData: '<b>html should be replaced</b>',
      isLoading: false,
      frameUrl: '',
    }
  },

  computed: {
    getAvatarUrl() {
      return this.$store.getters['auth/profileInfo']?.profile_pic
    },
  },

  methods: {
    executeAction(type) {
      if (type === 'confrim' && this.isLoading === false) {
        this.$emit('confirm')
      }

      if (type === 'cancel' && this.isLoading === false) {
        this.$refs.popup.hide()
        this.$emit('cancel')
      }
    },

    onChangeTab(tabId) {
      this.selectedTab = tabId
    },

    isTabActive(tabId) {
      return this.selectedTab === tabId
    },

    async stripeCheckout() {
      // const orgId = this.$store.getters['auth/organizationInfo']?.id
      // console.log('orgId', orgId)
      // if (!orgId) return

      this.frameUrl = useEndpoints.organization.card.add()
      httpClient.defaults.headers.common['Accept'] = 'text/html'

      this.isLoading = true
      await httpClient
        .post(this.frameUrl)
        .then((r) => {
          this.htmlData = r.data
          console.log('stripeCheckout', r.data)
          const ref = this.$refs.iframeRef
          ref.contentWindow.document.open()
          ref.contentWindow.document.write(r.data)
        })
        .catch((e) => {
          console.log('stripeChekcout err', e, e.status)
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: 'Server error',
            text: 'Failed to checkout via server!',
          })
        })
        .finally(() =>
          setTimeout(() => {
            this.isLoading = false
          }, 1000)
        )
    },
  },
}
</script>

<style lang="scss">
$spacingX: 4rem;
$spacingY: 4rem;

.m-overlay {
  @apply z-250 inset-0 w-full h-full fixed bg-gray-500 bg-opacity-50 transition-all duration-100 ease-in-out;
}
.m-wrapper {
  @apply z-220 relative overflow-y-auto mx-auto transition-all duration-300 ease-in-out;

  margin: $spacingY $spacingX;
}
.m-modal {
  @apply bg-white overflow-hidden relative rounded shadow-lg;

  // height width here
  height: calc(100vh - (2 * #{$spacingY}));
}

.m-body {
  @apply p-4 text-sm text-gray-700;
}

.x-container {
  @apply flex gap-4 py-5 px-7;

  .x-left {
    @apply w-28;
  }
  .x-right {
    @apply w-full;
  }
}

.x-tab-item {
  @apply pb-4 text-gray-600 border-b border-transparent cursor-pointer;
  @apply transition duration-200 ease-in-out;
  &:hover {
    @apply text-gray-700;
  }
  &.x-tab-active {
    @apply text-gray-700 border-b border-black font-bold;
  }
}

.x-notice {
  margin-top: 2rem;
  margin-left: 1rem;
  width: 309px;
  height: 106px;

  background: #eefbff;
  border-radius: 4px;
  color: #115266;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem;

  &.green {
    color: #33693f;
    background: #ecfff0;
  }
}
</style>
