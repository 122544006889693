var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative w-full"},[_c('google-places-autocomplete',{on:{"resultChanged":_vm.onChangingPlace,"resultCleared":_vm.onClearingPlace},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var context = ref.context;
var events = ref.events;
var actions = ref.actions;
return _c('div',{},[_c('label',{staticClass:"font-bold text-gray-700 text-14px"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(context.input),expression:"context.input"}],class:_vm.disabled
            ? 'places-autocomplete disabled'
            : 'places-autocomplete enabled',attrs:{"type":"search","autocomplete":"autocomplete-off","placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":(context.input)},on:{"focus":events.inputHasReceivedFocus,"input":[function($event){if($event.target.composing){ return; }_vm.$set(context, "input", $event.target.value)},events.inputHasChanged],"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return actions.selectItemFromList.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return actions.shiftResultsSelection.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return actions.unshiftResultsSelection.apply(null, arguments)}]}})])}},{key:"item",fn:function(ref){
            var place = ref.place;
return _c('span',{},[_c('i',{staticClass:"mr-3 fas fa-map-marker-alt text-oLightGray"}),_vm._v(" "+_vm._s(place.structured_formatting.main_text)+" "),_c('span',{staticClass:"text-xs text-oLightGray"},[_vm._v(" "+_vm._s(place.structured_formatting.secondary_text ? ("- " + (place.structured_formatting.secondary_text)) : "")+" ")])])}},{key:"activeItem",fn:function(ref){
            var place = ref.place;
return _c('span',{},[_c('i',{staticClass:"mr-3 fas fa-map-marker-alt text-oLightGray"}),_vm._v(" "+_vm._s(place.structured_formatting.main_text)+" "),_c('span',{staticClass:"text-xs text-oLightGray"},[_vm._v(" "+_vm._s(place.structured_formatting.secondary_text ? ("- " + (place.structured_formatting.secondary_text)) : "")+" ")])])}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }