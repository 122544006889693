<template>
  <slide-over-right
    :id="sorId"
    editType="marketplace"
    v-on:showModal="showModal"
  >
    <loading :active.sync="isLoading"></loading>

    <div class="flex flex-wrap px-6 pt-12 w-full">
      <div class="items-center  panel-title header-title">
        {{ titleText }}
      </div>
    </div>

    <div class="px-6">
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(confirmSubmit)"
          enctype="multipart/form-data"
        >
          <UploadAvatar
            v-model="form.brand_image"
            :current="form.brand_image"
            :class="`mb-3`"
          />

          <div class="flex items-center pt-8">
            <div class="pr-2 w-1/2">
              <text-input
                rules="required"
                label="Brand Name"
                placeholder="e.g. DUK"
                v-model="form.brand_name"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                name="Assigned Fleet"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Assigned Fleet *" />
                <t-rich-select
                  placeholder=""
                  :options="fleets"
                  valueAttribute="id"
                  textAttribute="name"
                  :hideSearchBox="false"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.fleet"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <ValidationProvider
                name="Assigned Manufacture"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Assigned Manufacture *" />
                <t-rich-select
                  placeholder=""
                  :options="lockManufacturers"
                  valueAttribute="id"
                  textAttribute="name"
                  :hideSearchBox="false"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.lock_manufacturer"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
            <div class="pl-2 w-1/2">
              <text-input
                rules="required"
                :label="getPerVehiclePriceTitle"
                placeholder="e.g. 100"
                type="number"
                v-model="form.unit_vehicle_price"
              />
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules="required"
                label="Number of vehicles to deploy"
                placeholder="e.g. 100"
                type="number"
                v-model="form.number_of_vehicles_to_deploy"
              />
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                name="Employee Size"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Employee Size *" />
                <t-rich-select
                  placeholder="e.g. 51-100"
                  :options="employeeSizes"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.company_employee_size"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <ValidationProvider
                name="Business Model"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Business Model *" />
                <t-rich-select
                  placeholder="Sharing"
                  :options="businessModels"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.business_model"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                name="Target Group"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Target Group *" />
                <t-rich-select
                  placeholder="B2B"
                  :options="targetGroups"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.target_group"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules="required"
                label="Founded Date"
                placeholder=""
                type="date"
                v-model="form.year_founded"
              />
            </div>
            <div class="pl-2 w-1/2">
              <text-input
                rules="required"
                label="Operation Start Date"
                placeholder=""
                type="date"
                v-model="form.operation_start_date"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules="required"
                label="Order Start Date"
                placeholder=""
                type="date"
                v-model="form.order_start_date"
              />
            </div>
            <div class="pl-2 w-1/2">
              <text-input
                rules="required"
                label="Order End Date"
                placeholder=""
                type="date"
                v-model="form.order_end_date"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules="required"
                label="Activation Date"
                placeholder=""
                type="date"
                v-model="form.activation_date"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules="required"
                label="Rent Share 1st year %"
                placeholder=""
                type="number"
                v-model="form.rent_share_1st_year_by_percentage"
              />
            </div>
            <div class="pl-2 w-1/2">
              <text-input
                rules="required"
                label="Rent Share 2nd year %"
                placeholder=""
                type="number"
                v-model="form.rent_share_2nd_year_by_percentage"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <text-input
                rules="required"
                label="Rent Share 3rd year %"
                placeholder=""
                type="number"
                v-model="form.rent_share_3rd_year_by_percentage"
              />
            </div>
            <div class="pl-2 w-1/2">
              <text-input
                rules="required"
                label="Rent Share 4th year %"
                placeholder=""
                type="number"
                v-model="form.rent_share_4th_year_by_percentage"
              />
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules=""
                label="Website URL"
                placeholder="https://www.example.com/id"
                v-model="form.website_url"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules=""
                label="LinkedIn URL"
                placeholder="https://www.linkedin.com/id"
                v-model="form.linkedin_url"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules=""
                label="Facebook URL"
                placeholder="https://www.facebook.com/id"
                v-model="form.facebook_url"
              />
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules=""
                label="Instagram URL"
                placeholder="https://www.instagram.com/id"
                v-model="form.instagram_url"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules=""
                label="Youtube URL"
                placeholder="https://www.youtube.com/id"
                v-model="form.youtube_url"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules=""
                label="Playstore URL"
                placeholder="https://www.playstore.com/id"
                v-model="form.android_play_store_url"
              />
            </div>
          </div>
          <div class="flex items-center pt-2">
            <div class="w-full">
              <text-input
                rules=""
                label="Appstore URL"
                placeholder="https://www.apple.com/id"
                v-model="form.ios_app_store_url"
              />
            </div>
          </div>

          <button type="submit" ref="submitButton" class="hidden">Save</button>
        </form>
      </ValidationObserver>
      <div class="flex justify-end items-center pr-2 mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">Cancel</anchor-button>
        </slide-over-right-trigger>
        <t-button type="submit" @click="submit()" class="ml-3">Save</t-button>
      </div>
    </div>
  </slide-over-right>
</template>
<script>
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
import { EventBus } from '@/utils/EventBus'
import xMan from '@/utils/xMan'
import SlideOverRight from '@/components/modals/SlideOverRight'
import TextInput from '@/components/form/TextInput'
import InputLabel from '@/components/form/InputLabel'
import AnchorButton from '@/components/form/AnchorButton'
import { UploadAvatar } from '@/components/form'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
export default {
  name: 'AddEditVehicleManufacture',
  components: {
    SlideOverRight,
    TextInput,
    InputLabel,
    AnchorButton,
    SlideOverRightTrigger,
    UploadAvatar,
  },
  data() {
    return {
      sorId: MarketplaceConfig.events.sorId,
      isLoading: false,
      perVehiclePriceCurrency: '',
      form: {
        brand_image: null,
        brand_name: null,
        fleet: null,
        lock_manufacturer: null,
        unit_vehicle_price: null,

        number_of_vehicles_to_deploy: null,
        business_model: null,
        target_group: null,
        company_employee_size: null,

        year_founded: null,
        activation_date: null,
        order_start_date: null,
        order_end_date: null,
        operation_start_date: null,

        rent_share_1st_year_by_percentage: null,
        rent_share_2nd_year_by_percentage: null,
        rent_share_3rd_year_by_percentage: null,
        rent_share_4th_year_by_percentage: null,

        website_url: null,
        facebook_url: null,
        linkedin_url: null,
        instagram_url: null,
        youtube_url: null,
        android_play_store_url: null,
        ios_app_store_url: null,
      },
      itemId: '',
      fleets: [],
      lockManufacturers: [],
      businessModels: [
        { text: 'Sharing', value: 'SH' },
        { text: 'Store Rental', value: 'SR' },
      ],
      targetGroups: [
        { text: 'B2B', value: 'B2B' },
        { text: 'B2C', value: 'B2C' },
      ],
      employeeSizes: [
        {
          text: '1-10',
          value: '1-10',
        },
        {
          text: '11-50',
          value: '11-50',
        },
        {
          text: '51-100',
          value: '51-100',
        },
        {
          text: '101-250',
          value: '101-250',
        },
        {
          text: '251-500',
          value: '251-500',
        },
        {
          text: '501-1000',
          value: '501-1000',
        },
        {
          text: '1001-2500',
          value: '1001-2500',
        },
        {
          text: '2501-5000',
          value: '2501-5000',
        },
        {
          text: '5001-10000',
          value: '5001-10000',
        },
        {
          text: '10000+',
          value: '10000+',
        },
      ],
    }
  },
  computed: {
    isEditing: function() {
      // enabled status-switch by default
      return this.itemId.length > 1
    },
    titleText() {
      return this.isEditing ? 'Edit Marketplace' : 'Add Marketplace'
    },
    getPerVehiclePriceTitle() {
      if (this.perVehiclePriceCurrency) {
        return `Per vehicle price (${this.perVehiclePriceCurrency})`
      } else {
        return `Per vehicle price`
      }
    },
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler(updatedData) {
        if (updatedData.fleet && this.fleets) {
          const selectedFleet = this.fleets.find(
            (item) => item.id === updatedData.fleet
          )
          this.perVehiclePriceCurrency = selectedFleet.country.currency_symbol
        }
      },
    },
  },
  mounted() {
    EventBus.$on(MarketplaceConfig.events.editingData, (data) => {
      this.perVehiclePriceCurrency = ''
      this.init()
      this.itemId = data.itemId
      this.lockManufacturers = data.lockManufacturers
      this.fleets = data.fleets
      if (data.itemId) {
        this.isLoading = true
        this.$http
          .get(MarketplaceConfig.api.details(data.itemId))
          .then((res) => {
            this.isLoading = false
            const resData = res.data.data
            this.form.brand_image = resData.brand_image
            this.form.brand_name = resData.brand_name
            this.form.fleet = resData.fleet.id
            this.form.lock_manufacturer = resData.lock_manufacturer
            this.form.unit_vehicle_price = resData.unit_vehicle_price
            this.form.number_of_vehicles_to_deploy =
              resData.number_of_vehicles_to_deploy
            this.form.business_model = resData.business_model
            this.form.target_group = resData.target_group
            this.form.company_employee_size = resData.company_employee_size
            this.form.year_founded = this.dateSplitter(resData.year_founded)
            this.form.activation_date = this.dateSplitter(
              resData.activation_date
            )
            this.form.order_start_date = this.dateSplitter(
              resData.order_start_date
            )
            this.form.order_end_date = this.dateSplitter(resData.order_end_date)
            this.form.operation_start_date = this.dateSplitter(
              resData.operation_start_date
            )
            this.form.rent_share_1st_year_by_percentage =
              resData.rent_share_1st_year_by_percentage
            this.form.rent_share_2nd_year_by_percentage =
              resData.rent_share_2nd_year_by_percentage
            this.form.rent_share_3rd_year_by_percentage =
              resData.rent_share_3rd_year_by_percentage
            this.form.rent_share_4th_year_by_percentage =
              resData.rent_share_4th_year_by_percentage
            this.form.website_url = resData.website_url
            this.form.facebook_url = resData.facebook_url
            this.form.linkedin_url = resData.linkedin_url
            this.form.instagram_url = resData.instagram_url
            this.form.youtube_url = resData.youtube_url
            this.form.android_play_store_url = resData.android_play_store_url
            this.form.ios_app_store_url = resData.ios_app_store_url
          })
          .catch((e) => console.log(e))
        //edit
      }
    })
  },
  methods: {
    dateSplitter(dString) {
      return dString.split('T')[0]
    },
    showModal() {
      dispatchEvent(new Event(MarketplaceConfig.events.sorToggle))
    },
    init() {
      this.form.brand_image = null
      this.form.brand_name = null
      this.form.fleet = null
      this.form.lock_manufacturer = null
      this.form.unit_vehicle_price = null
      this.form.number_of_vehicles_to_deploy = null
      this.form.business_model = null
      this.form.target_group = null
      this.form.company_employee_size = null
      this.form.year_founded = null
      this.form.activation_date = null
      this.form.order_start_date = null
      this.form.order_end_date = null
      this.form.operation_start_date = null
      this.form.rent_share_1st_year_by_percentage = null
      this.form.rent_share_2nd_year_by_percentage = null
      this.form.rent_share_3rd_year_by_percentage = null
      this.form.rent_share_4th_year_by_percentage = null
      this.form.website_url = null
      this.form.facebook_url = null
      this.form.linkedin_url = null
      this.form.instagram_url = null
      this.form.youtube_url = null
      this.form.android_play_store_url = null
      this.form.ios_app_store_url = null
    },
    submit() {
      this.$refs.submitButton.click()
    },
    async confirmSubmit() {
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? MarketplaceConfig.api.update(this.itemId)
        : MarketplaceConfig.api.create()

      const formProxy = {
        ...this.form,
      }

      const propertyNames = Object.keys(formProxy)
      propertyNames.map((name) => {
        if (formProxy[name] === null) {
          delete formProxy[name]
        }
      })
      if (typeof this.form.brand_image === 'string') {
        delete formProxy.brand_image
      }

      formProxy.year_founded = formProxy.year_founded + 'T00:00:00'
      formProxy.activation_date = formProxy.activation_date + 'T00:00:00'
      formProxy.order_start_date = formProxy.order_start_date + 'T00:00:00'
      formProxy.order_end_date = formProxy.order_end_date + 'T00:00:00'
      formProxy.operation_start_date =
        formProxy.operation_start_date + 'T00:00:00'
      let data = new xMan(formProxy).toFormData()
      let message = this.isEditing
        ? 'Marketplace updated successfully'
        : 'Marketplace added successfully'

      this.isLoading = true

      try {
        let response = await this.$http({
          method,
          url,
          data,
        })

        console.log('response = ', url, '  ', method, '  ')
        console.log('data = ', response)

        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(MarketplaceConfig.events.sorToggle))
        dispatchEvent(new Event(MarketplaceConfig.events.refresh))
        this.$emit('add-edit')
        // Reset the form
        this.init()
        this.$notify({
          group: 'bottomLeft',
          type: 'success',
          title: 'Success',
          text: message,
        })
        // Resolved
        return response.status
      } catch (error) {
        const e = error.response
        console.log('err', e)

        this.$notify(
          {
            group: 'bottomLeft',
            type: 'error',
            title: `Error Occured [Code: ${e.status}]`,
            text: e.data ?? 'The action could not be executed',
          },
          7000
        )

        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss">
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
</style>
