<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form
      @submit.prevent="handleSubmit(submitStep)"
      enctype="multipart/form-data"
      class="pt-6 sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-10"
    >
      <div>
        <h1 class="pb-2">Payment Details</h1>
        <label class="flex items-center w-full mt-4 mb-4 cursor-pointer">
          <TCheckbox v-model="is_not_banking_details_required" />

          <span
            class="ml-3 font-semibold select-none text-14p mt-px text-oBlack"
          >
            I don’t have Payment details
          </span>
        </label>
      </div>

      <div class="grid grid-cols-2 gap-4 mt-8">
        <AppInput
          :rules="!form.is_banking_details_required ? '' : 'required'"
          :disabled="!form.is_banking_details_required"
          label="Bank Account Name"
          placeholder="e.g Junior Martin"
          v-model="form.account_name"
        />

        <AppInput
          :rules="!form.is_banking_details_required ? '' : 'required'"
          :disabled="!form.is_banking_details_required"
          type="number"
          label="Bank Account No"
          placeholder="e.g 110995330"
          v-model="form.account_number"
        />
      </div>

      <div class="grid grid-cols-2 gap-4">
        <AppInput
          placeholder="e.g. NY City Bank, NY"
          :rules="!form.is_banking_details_required ? '' : 'required'"
          :disabled="!form.is_banking_details_required"
          label="Branch"
          v-model="form.branch_name"
        />

        <AppInput
          name="Country"
          label="Country"
          :rules="!form.is_banking_details_required ? '' : 'required'"
          :disabled="!form.is_banking_details_required"
          :options.sync="countries"
          valueAttribute="id"
          textAttribute="name"
          placeholder="Select country"
          v-model="form.country"
          type="richselect"
        />
      </div>

      <AppInput
        placeholder="e.g. 026013673"
        :rules="!form.is_banking_details_required ? '' : 'required'"
        :disabled="!form.is_banking_details_required"
        label="Routing Number"
        type="number"
        v-model="form.routing_number"
      />

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { xMan, deepCompareObjects } from '@/utils'
import { OrganizationUserConfig } from '@/config/OrganizationUserConfig'

export default {
  name: 'Step2',
  components: {
    // InputLabel: () => import('@/components/form/InputLabel'),
    // TextInput: () => import('@/components/form/TextInput')
  },
  props: {
    countries: {
      required: true,
    },
    userId: {
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      is_not_banking_details_required: true,
      form: {
        account_name: '',
        account_number: '',
        branch_name: '',
        country: '',
        routing_number: '',
        is_banking_details_required: false,
      },
    }
  },
  watch: {
    // sync props.formData with $data.from
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          this.form = { ...data }
          this.is_not_banking_details_required = !data.is_banking_details_required
        }
      },
    },
    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        // don't notify if editing data & user input data are same
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // otherwise notify whenever there's new input / change
        this.$emit('dirty', { step: 2, data: this.form })
      },
    },
    is_not_banking_details_required: {
      immediate: true,
      deep: true,
      handler(data) {
        this.form.is_banking_details_required = !data
      },
    },
  },
  methods: {
    async submit() {
      this.$refs.submitButton.click()
    },
    async submitStep() {
      let url = OrganizationUserConfig.api.payment_create(this.userId)
      let data = new xMan(this.form).toFormData()
      console.log(data)
      let method = 'POST'

      this.isLoading = true
      await this.$http({ method, url, data })
        .then((res) => {
          this.$emit('save', { step: 2, data: res.data })

          // todo: toast message based on mode
          const message = 'Payment updated successfully'

          this.$notify(
            {
              group: 'important',
              type: 'success',
              title: 'Success',
              text: message,
            },
            5000
          )
        })
        .catch((err) => {
          // todo: emit error
          var errorData = Object.values(err.message)
          if (errorData.length > 0) {
            this.$emit('show_error', errorData[0][0])
          }

          this.$notify(
            {
              group: 'important',
              type: 'error',
              title: 'Error occurred!',
              text: err.response.data.message ?? err.response.data,
            },
            12000
          )
          console.warn(err)
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 22px;
  font-weight: bold;
  color: #2e2e39;
}
</style>
