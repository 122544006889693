<template>
  <header
    class="sticky top-0 flex items-center justify-between w-full bg-white h-55px"
    :class="scrolled ? 'shadow-md' : ''"
    style="z-index: 200"
  >
    <div id="topbar-left" class="lg:px-4 lg:py-3 sm:p-0">
      <div
        class="flex items-center w-auto h-full   lg:ml-0"
        :class="$user ? 'ml-16' : 'ml-5'"
        v-show="logoVisibility"
        @click="gotoHome()"
      >
        <router-link :to="{ name: 'Home' }">
          <!-- <img
            src="@/assets/marketplace/brand-logo/sol-ride.png"
            class="p-2 "
            :class="$user ? `` : `w-32 md:w-auto`"
          /> -->
          <img
            :src="BRAND_CONFIG.ORG_DARK_LOGO"
            class="p-2"
            :class="$user ? '' : 'w-32 md:w-auto'"
            :style="
              `width: ${BRAND_CONFIG.LOGO_SIZE.TOPBAR.WIDTH}px; height: ${BRAND_CONFIG.LOGO_SIZE.TOPBAR.HEIGHT}px;`
            "
          />
        </router-link>
      </div>

      <!-- <oto-search class="hidden lg:block" /> -->
    </div>

    <div id="topbar-right" class="flex items-center">
      <!-- <updates-dropdown-trigger /> -->
      <!-- <notifications-dropdown-trigger /> -->
      <user-dropdown-trigger />
    </div>
  </header>
</template>
<script>
// import OtoSearch from "./ui/OtoSearch";
// import UpdatesDropdownTrigger from '@/components/layout/topbar/UpdatesDropdownTrigger'
// import NotificationsDropdownTrigger from '@/components/layout/topbar/NotificationsDropdownTrigger'
import UserDropdownTrigger from '@/components/layout/topbar/UserDropdownTrigger'
import { BRAND_CONFIG } from '@/config/brand.config'

export default {
  name: 'AppTopbar',
  components: {
    // OtoSearch,
    // UpdatesDropdownTrigger,
    // NotificationsDropdownTrigger,
    UserDropdownTrigger,
  },
  props: {
    logoVisibility: {
      type: Boolean,
      required: false,
      default: false,
    },
    organizationLogo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      redirectStatus: false,
      scrolled: false,
      BRAND_CONFIG: BRAND_CONFIG,
    }
  },
  computed: {
    // getLogoVisibility() {
    //   return this.$store.getters['navigation/getSidebarCollapsed']
    // },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted() {
    const routeArray = this.$router.currentRoute.path.split('/')
    console.log('routeArray', routeArray)
    if (
      routeArray.length === 4 &&
      routeArray.includes('payment') &&
      routeArray.includes('marketplaces-preorder')
    ) {
      localStorage.setItem('route-path', this.$router.currentRoute.path)
    }
    if (localStorage.getItem('route-path') !== null) {
      this.$router.push(localStorage.getItem('route-path'))
    }
    try {
      // this.organizationLogo = JSON.parse(
      //   localStorage.getItem('organization')
      // ).avatar
      this.redirectStatus = true
    } catch (err) {
      console.log('erros', err)
      this.redirectStatus = false
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0
    },
    gotoHome() {
      localStorage.removeItem('route-path')
      this.$router.push('/')
    },
  },
}
</script>

<style>
#topbar-left img {
  height: 50px;
}
.w-55px {
  width: 55px;
}
.w-132px {
  width: 132px;
}
.h-55px {
  height: 55px;
}
.bg-red-notification {
  background-color: #d90a20;
}
.text-red-notification {
  color: #d90a20;
}
</style>
