export const StyleConfig = {}

export const ZIndex = {
  topbar: 30,
  sidebar: {
    wrapper: 30,
    links: 20,
  },
  dropdown: {
    overlay: 50,
    trigger: 90,
    container: 60,
  },
  slideover: {
    overlay: 50,
    container: 60,
  },
}

export const OtoColors = {
  ash: '#b4b9bc', // faded-ash
  gray: '#626262', // icons
  lightGray: '#c3c3d1', // borders
  dark: '#030303', // very-black
  white: '#ffffff', // typography
  black: '#323740', // typography
  charcoal: '#323841', // borders
  red: '#d90a20', // background
  green: '#09aa35', // background
  yellow: '#ffed00', // background
  purple: '#7d3cf8', // background
  orange: '#f67606', // background
}
