<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <!-- payment -->
      <section>
        <!-- row-1 -->
        <div class="grid grid-cols-2 gap-4 pb-4">
          <TCheckbox
            v-model="form.useOrgPaymentGateway"
            :label="`Use Default Payment Gateway`"
            :name="`Use Default Payment Gateway`"
            wrapped
          />
        </div>
        <div>
          <AppInput
            v-model="form.payment.payment_gateway"
            type="richselect"
            name="Payment Gateway"
            label="Payment Gateway"
            rules="required"
            placeholder="Select payment gateway"
            text-attribute="name"
            value-attribute="id"
            :options="paymentGateways"
            :disabled="form.useOrgPaymentGateway"
            hide-search-box
          />
        </div>
        <!-- /row-1 -->

        <div class="grid grid-cols-2 gap-4 pb-4">
          <TCheckbox
            v-model="form.payment.sandbox_mode"
            :label="`Enable Live Mode`"
            :name="`Enable Live Mode`"
            :disabled="form.useOrgPaymentGateway"
            wrapped
          />
        </div>

        <!-- row-2 -->
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.payment.client_id"
            type="password"
            name="Client ID"
            :rules="form.payment.sandbox_mode ? '' : 'required'"
            label="Client ID"
            placeholder="Client ID"
            :disabled="form.useOrgPaymentGateway"
          />

          <AppInput
            v-model="form.payment.sandbox_client_id"
            type="password"
            name="Live Client ID"
            :rules="form.payment.sandbox_mode ? 'required' : ''"
            label="Live Client ID"
            placeholder="Live Client ID"
            :disabled="form.useOrgPaymentGateway"
          />
        </div>
        <!-- /row-3 -->

        <!-- row-3 -->
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.payment.secret_key"
            type="password"
            name="Secret Key"
            :rules="form.payment.sandbox_mode ? '' : 'required'"
            label="Secret Key"
            placeholder="Secret Key"
            :disabled="form.useOrgPaymentGateway"
          />

          <AppInput
            v-model="form.payment.sandbox_secret_key"
            type="password"
            name="Live Secret Key"
            :rules="form.payment.sandbox_mode ? 'required' : ''"
            label="Live Secret Key"
            placeholder="Live Secret Key"
            :disabled="form.useOrgPaymentGateway"
          />
        </div>
        <!-- /row-3 -->

        <!-- row-4 -->
        <div class="grid grid-cols-2 gap-4">
          <AppInput
            v-model="form.payment.app_key"
            type="password"
            name="App Key"
            label="App Key"
            rules=""
            placeholder="App Key"
            :disabled="form.useOrgPaymentGateway"
          />

          <AppInput
            v-model="form.payment.sandbox_app_key"
            type="password"
            name="Live App Key"
            label="Live App Key"
            rules=""
            placeholder="Live App Key"
            :disabled="form.useOrgPaymentGateway"
          />
        </div>
        <!-- /row-4 -->

        <div>
          <AppInput
            v-model="form.payment.extra_key"
            type="password"
            name="Extra Key"
            rules=""
            label="Extra Key"
            placeholder="Extra Key"
            :disabled="form.useOrgPaymentGateway"
          />
        </div>
      </section>
      <!-- /payment -->

      <button type="submit" ref="submitButton" class="hidden">Save</button>
    </form>
  </ValidationObserver>
</template>

<script>
import { useEndpoints } from '@/composables'
import { deepCompareObjects } from '@/utils'
export default {
  name: 'Step3',

  components: {},

  props: {
    paymentGateways: {
      type: Array,
      required: true,
    },
    primaryKey: {
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    // const { payment, link, domain } = getFormModel({ step: 3 })
    // console.log(payment, link, domain, 'form3')

    return {
      isLoading: false,
      form: {
        payment: {
          payment_gateway: '',
          client_id: '',
          secret_key: '',
          app_key: '',
          extra_key: '',
          sandbox_mode: false,
          sandbox_client_id: '',
          sandbox_secret_key: '',
          sandbox_app_key: '',
        },
        useOrgPaymentGateway: false,
      },
      orgPaymentDetails: {
        payment_gateway: '',
        client_id: '',
        secret_key: '',
        app_key: '',
        extra_key: '',
        sandbox_mode: false,
        sandbox_client_id: '',
        sandbox_secret_key: '',
        sandbox_app_key: '',
      },
      fleetPaymentDetails: {
        payment_gateway: '',
        client_id: '',
        secret_key: '',
        app_key: '',
        extra_key: '',
        sandbox_mode: false,
        sandbox_client_id: '',
        sandbox_secret_key: '',
        sandbox_app_key: '',
      },
    }
  },
  created() {
    const orgPaymentConfig = this.$store.getters['auth/organizationInfo']
      .default_payment_gateway_config
    this.orgPaymentDetails.payment_gateway = orgPaymentConfig.payment_gateway
    this.orgPaymentDetails.client_id = orgPaymentConfig.client_id
    this.orgPaymentDetails.secret_key = orgPaymentConfig.secret_key
    this.orgPaymentDetails.app_key = orgPaymentConfig.app_key
    this.orgPaymentDetails.extra_key = orgPaymentConfig.extra_key
    this.orgPaymentDetails.sandbox_mode = orgPaymentConfig.sandbox_mode
    this.orgPaymentDetails.sandbox_client_id =
      orgPaymentConfig.sandbox_client_id
    this.orgPaymentDetails.sandbox_secret_key =
      orgPaymentConfig.sandbox_secret_key
    this.orgPaymentDetails.sandbox_app_key = orgPaymentConfig.sandbox_app_key
  },

  computed: {},

  watch: {
    // sync props.formData with $data.from
    'formData': {
      deep: true,
      immediate: true,
      handler(data) {
        if (data) {
          // todo: if domains are < 1 it might be in draft status
          // if paymentId is empty, it hasn't been created yet (probably in draft status)
          // links are optional fields
          const { payment, paymentId, orgId, useOrgPaymentGateway } = data
          this.form = { payment, paymentId, orgId, useOrgPaymentGateway }
          this.fleetPaymentDetails = payment
            ? payment
            : this.fleetPaymentDetails
          if (useOrgPaymentGateway) {
            this.form.payment = this.orgPaymentDetails
          } else {
            this.form.payment = this.fleetPaymentDetails
          }
          console.log(this.form, 'ff3')
        }
      },
    },

    // notify form is dirty & user should confirm before exiting
    'form': {
      deep: true,
      immediate: false,
      handler(updatedFormData) {
        // don't notify if editing data & user input data are same
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // otherwise notify whenever there's new input / change
        this.$emit('dirty', { step: 3, data: this.form })
      },
    },
    'form.useOrgPaymentGateway': function(value) {
      if (value) {
        this.form.payment = this.orgPaymentDetails
      } else {
        this.form.payment = this.fleetPaymentDetails
      }
    },
  },

  methods: {
    async submit() {
      await this.$refs.submitButton.click()
    },
    async submitStep() {
      /**
       * Payment
       *
       * in draft status -> the payment gateway might not been created,
       * meaning there's no paymentId, in that case, it need to be created.
       */
      const paymentId = this.form.paymentId

      const paymentUrl = paymentId
        ? useEndpoints.paymentGateway.update(paymentId)
        : useEndpoints.paymentGateway.create()

      const paymentMethod = paymentId ? 'PATCH' : 'POST'

      console.log({ paymentMethod })

      const paymentData = new FormData()
      const paymentFormData = {
        ...this.form.payment,
        organization: this.form.orgId,
        fleet: this.primaryKey,
      }

      for (const k in paymentFormData) {
        paymentData.append(k, paymentFormData[k])
      }
      this.isLoading = true

      if (this.form.useOrgPaymentGateway) {
        this.updatePaymentGatewayConfigFlag(this.form.useOrgPaymentGateway)
        return
      }

      const paymentReq = this.$http({
        url: paymentUrl,
        method: paymentMethod,
        data: paymentData,
      })
      const requests = [paymentReq]

      // submit

      await this.$http
        .all(requests)
        .then(
          this.$http.spread((...responses) => {
            const [paymentRes] = responses
            const data = {
              payment: paymentRes.data,
            }
            console.log(data)
            this.updatePaymentGatewayConfigFlag(this.form.useOrgPaymentGateway)
            // note raw responses are being sent
            // this.$emit('save', { step: 3, data })

            const message = `Fleet Payment Settings ${
              this.isEditing ? 'updated' : 'added'
            } successfully`

            this.$notify({
              group: 'bottomLeft',
              type: 'success',
              title: 'Success',
              text: message,
            })
          })
        )
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response.data.detail,
          })
        })
    },
    async updatePaymentGatewayConfigFlag(status) {
      const configData = new FormData()
      configData.append('use_org_payment_gateway_config', status)
      await this.$http
        .patch(useEndpoints.fleet.update(this.primaryKey), configData)
        .then((res) => {
          console.log('step-3-fleet-update', res)
          this.$emit('save', { step: 3 })
        })
        .catch((err) => {
          console.log('step-3-fleet-update-error', { err })
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
.section-name {
  @apply text-sm font-bold text-gray-500;
}
</style>
