<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form
      @submit.prevent="handleSubmit(submitStep)"
      enctype="multipart/form-data"
      class="pt-6 sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-10"
    >
      <div>
        <h1 class="pb-2">Personal Identification</h1>

        <!-- <div class="flex items-center gap-2">
          <div class="flex items-center gap-2">
            <TToggle v-model="form.is_verified" variant="success" />

            <div class="w-16 font-medium text-14px text-oDark">
              {{ form.is_verified ? 'Verified' : 'Unverified' }}
            </div>
          </div>

          <label class="flex items-center gap-1 text-sm font-bold">
            <TToggle variant="success" v-model="form.is_document_required" />
            <span>{{
              form.is_document_required ? 'Required' : 'Not Required'
            }}</span>
          </label>
        </div> -->
        <label class="flex items-center w-full mt-4 mb-4 cursor-pointer">
          <TCheckbox v-model="is_not_document_required" />

          <span
            class="ml-3 font-semibold select-none text-14p mt-px text-oBlack"
          >
            I want to skip this step
          </span>
        </label>
      </div>
      <div class="flex items-center pt-8">
        <AppInput
          :rules="!form.is_document_required ? '' : 'required'"
          :disabled="!form.is_document_required"
          label="NID Number"
          placeholder="e.g. 92344563432"
          v-model="form.nid_number"
        />
      </div>

      <div class="grid grid-cols-2 gap-4 mt-8 ">
        <div>
          <ValidationProvider
            tag="div"
            class="mt-2"
            name="NID Front side"
            :rules="!form.is_document_required ? '' : 'required'"
            v-slot="{ errors }"
          >
            <input-label
              :error="errors[0]"
              :text="`NID Front Side ${!form.is_document_required ? '' : '*'}`"
            />

            <UploadImageAlt
              class="max-h-14"
              :current="form.nid_front_side_pic"
              :text="`Drag and drop the front side photo of NID`"
              :button-text="`Upload Front Side`"
              v-model="form.nid_front_side_pic"
            />

            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>

        <div>
          <ValidationProvider
            tag="div"
            class="mt-2"
            name="NID Front side"
            :rules="!form.is_document_required ? '' : 'required'"
            v-slot="{ errors }"
          >
            <input-label
              :error="errors[0]"
              :text="`NID Back Side ${!form.is_document_required ? '' : '*'}`"
            />

            <UploadImageAlt
              class="max-h-14"
              :current="form.nid_back_side_pic"
              :text="`Drag and drop the back side photo of NID`"
              :button-text="`Upload Back Side`"
              v-model="form.nid_back_side_pic"
            />

            <input-error-item :message="errors[0]" />
          </ValidationProvider>
        </div>
      </div>
      <div>
        <label class="flex items-center w-full mt-4 mb-4 cursor-pointer">
          <TCheckbox
            v-model="form.is_verified"
            :disabled="is_not_document_required"
          />

          <span
            :class="[
              'ml-3 font-semibold select-none text-14p mt-px',
              is_not_document_required ? 'text-gray-500' : 'text-oBlack',
            ]"
          >
            Verified
          </span>
        </label>
      </div>

      <h1 class="pt-12">Driving License (optional)</h1>
      <div class="mt-6">
        <!-- <input-label :error="``"  /> -->
        <AppInput
          name="Driving License"
          label="Driving License"
          :options="getLicenseOptions"
          :text-attribute="`text`"
          :value-attribute="`value`"
          :hide-search-box="true"
          :disabled="!form.is_document_required"
          v-model="form.has_driving_license"
        />
      </div>

      <!-- disabled for now -->
      <div class="grid grid-cols-2 gap-4 mt-8" v-if="false">
        <div>
          <input-label :error="``" text="Driving License Front Side" />
          <upload-image
            type="w-full"
            :idx="0"
            :current="form.nid_front_side_pic"
            @input="notifyDirty(true)"
            title="Drag and droff front side"
            :disabled="is_not_document_required"
          />
        </div>

        <div>
          <input-label :error="``" text="Driving License Back Side" />
          <upload-image
            type="w-full"
            :idx="1"
            :current="form.nid_back_side_pic"
            @input="notifyDirty(true)"
            title="Drag and droff back side"
            :disabled="is_not_document_required"
          />
        </div>
      </div>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { xMan, deepCompareObjects } from '@/utils'
import { OrganizationUserConfig } from '@/config/OrganizationUserConfig'
import { EventBus } from '@/utils/EventBus'
// import TextInput from '@/components/form/TextInput'
import InputLabel from '@/components/form/InputLabel'
import UploadImageAlt from '@/components/form/UploadImageAlt'

export default {
  name: 'Step3',
  components: {
    InputLabel,
    UploadImageAlt,
    // TextInput,
  },
  props: {
    userId: {
      required: true,
    },
    countries: {
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function() {
    return {
      isLoading: false,
      is_not_document_required: true,
      form: {
        nid_number: '',
        nid_front_side_pic: '',
        nid_back_side_pic: '',
        is_verified: false,
        has_driving_license: false,
        is_document_required: false,
      },
    }
  },
  computed: {
    getLicenseOptions: () => [
      { text: 'Yes', value: true },
      { text: 'No', value: false },
    ],
  },
  watch: {
    // sync props.formData with $data.from
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          this.form = { ...data }
          this.is_not_document_required = !data.is_document_required
        }
      },
    },
    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        // don't notify if editing data & user input data are same
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // otherwise notify whenever there's new input / change
        this.$emit('dirty', { step: 3, data: this.form })
      },
    },
    is_not_document_required: {
      immediate: true,
      deep: true,
      handler(data) {
        this.form.is_document_required = !data
        EventBus.$emit('checkbox-status', this.is_not_document_required)
      },
    },
  },
  methods: {
    async submit() {
      await this.$refs.submitButton.click()
    },
    async submitStep() {
      const fromDataProxy = { ...this.form }

      let url = OrganizationUserConfig.api.document_create(this.userId)
      let method = 'POST'

      // don't submit string where file is expected
      if (
        fromDataProxy.nid_front_side_pic == null ||
        typeof fromDataProxy.nid_front_side_pic === 'string'
      ) {
        delete fromDataProxy.nid_front_side_pic
      }

      if (
        fromDataProxy.nid_back_side_pic == null ||
        typeof fromDataProxy.nid_back_side_pic === 'string'
      ) {
        delete fromDataProxy.nid_back_side_pic
      }

      let data = new xMan(fromDataProxy).toFormData()
      console.log('form = ', fromDataProxy, url)
      let message = this.isEditing
        ? 'Document Info updated successfully'
        : 'Document Info added successfully'

      this.isLoading = true
      await this.$http({ method, url, data })
        .then((res) => {
          console.log({ res: res.data })
          this.$notify(
            {
              group: 'important',
              type: 'success',
              title: 'Success',
              text: message,
            },
            5000
          )

          this.$emit('save', { step: 3, data: res.data })
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'important',
              type: 'error',
              title: 'Error occurred!',
              text: err.response.data.message ?? err.response.data,
            },
            12000
          )
          console.warn(err)
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>
<style lang="scss" scoped>
h1 {
  font-size: 22px;
  font-weight: bold;
  color: #2e2e39;
}
</style>
