<template>
  <EdgeStack :id="esId">
    <template v-slot:header>
      <div class="pb-3 panel-title">{{ getTitleText }}</div>

      <XStepper
        :id="esId"
        :steps="stepperSteps"
        :mode="stepperMode"
        v-model="stepperCurrentStep"
        ref="xstepper"
      />
    </template>

    <template v-slot:footer>
      <XStepperNavigation
        v-if="isEditing"
        :id="esId"
        :steps="stepperSteps"
        :submit-button-text="getSubmitButtonText"
        @submit="onSubmit"
        v-model="stepperCurrentStep"
      />

      <AppButton
        v-if="!isEditing"
        :text="getSubmitButtonText"
        @click="onSubmit({ step: stepperCurrentStep })"
      />
    </template>

    <template v-slot:default>
      <keep-alive>
        <template v-if="stepperCurrentStep === 1">
          <Step1
            :roles="roles"
            :fleets="fleets"
            :user-id="theUserId"
            :currencies="currencies"
            :form-data="getStep1FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
            ref="step1"
          />
        </template>

        <template v-else-if="stepperCurrentStep === 2">
          <Step2
            :countries="countries"
            :user-id="theUserId"
            :form-data="getStep2FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
            ref="step2"
          />
        </template>

        <template v-else-if="stepperCurrentStep === 3">
          <Step3
            :countries="countries"
            :user-id="theUserId"
            :form-data="getStep3FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
            ref="step3"
          />
          <!-- todo: save dirty step info as well -->
        </template>

        <template v-else-if="stepperCurrentStep === 4">
          <Step4
            :countries="countries"
            :user-id="theUserId"
            :form-data="getStep4FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
            ref="step4"
          />
          <!-- todo: if user id is missing, show error on steps that requres it -->
        </template>
      </keep-alive>
    </template>
  </EdgeStack>
</template>

<script>
import roleApi from '@/config/api/role'
import { FleetConfig } from '@/config/FleetConfig'
import { DropdownConfig } from '@/config/DropdownConfig'

import EdgeStack from '@/components/modals/EdgeStack'
import XStepper from '@/components/stepper/XStepper'
import XStepperNavigation from '@/components/stepper/XStepperNavigation'
import AppButton from '@/components/form/AppButton.vue'
import Step1 from './add-edit/Step1'
import Step2 from './add-edit/Step2'
import Step3 from './add-edit/Step3'
import Step4 from './add-edit/Step4'

export default {
  name: 'UserAddEdit',
  components: {
    EdgeStack,
    XStepper,
    XStepperNavigation,
    AppButton,
    Step1,
    Step2,
    Step3,
    Step4,
  },
  props: {
    esId: {
      type: String,
      default: 'user-add-edit',
    },
    stepperMode: {
      type: String,
      default: 'free',
    },
    stepperStep: {
      type: Number,
      default: 1,
    },
    userId: {
      type: null,
      required: false,
    },
    userData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      errors: [],
      // Unless a step is free, user can't navigate to that step by clicking on the step
      // todo: also support this feature on xStepperNavigation & xStepper.navigate() helpers
      stepperSteps: this.isEditing
        ? [
            { title: 'Profile', free: true },
            { title: 'Payment', free: true },
            { title: 'Document', free: true },
            { title: 'Address', free: true },
          ]
        : [
            { title: 'Profile', free: true },
            { title: 'Payment', free: false },
            { title: 'Document', free: false },
            { title: 'Address', free: false },
          ],
      // this is the proxy of prop.stepperStep. It's used as v-model for steps
      // it needs to be in sync -> watched
      stepperCurrentStep: null,

      // these data are required for the stepper steps
      roles: null,
      fleets: null,
      countries: null,
      currencies: null,

      // theUserId is required in all steps other than step 1 (for updating with patch req)
      // it needs to be present in both add & edit mode.
      //
      // It's either received from 'save' event of step 1 or from the prop (in editing mode)
      // it needs to be watched to stay in sync
      theUserId: null,
    }
  },
  computed: {
    // User data is meant to be passed to be in edit mode
    // ? is it better to also check stepperMode? i'll think about it later
    // todo: don't allow navigating to next step in editing mode if current step is dirty
    // todo: -> need to save dirty state with step data, pass prob allowNext & allowPrev on XStepperNavigation component
    isEditing() {
      return !!this.userData
    },
    getTitleText() {
      return this.isEditing ? 'Edit Organization User' : 'Add Organization User'
    },
    getSubmitButtonText() {
      if (this.stepperCurrentStep > this.stepperSteps.length - 1)
        return 'Finish'

      return this.isEditing ? 'Update' : 'Save & Continue'
    },
    // get steps formData prop getters : either valid object or null.
    // generate from prop.userData for setting edit mode data.
    //
    // pass the computed formData to all steps in all mode & watch prop.formData in step component
    // then set $data.form if props.formData are valid object (not null)
    //
    // the returned object must resemble as the $data.form object defined in different steps
    // it'll be watched & synced in the stepX component.
    getStep1FormData() {
      if (this.userData) {
        const user = this.userData

        // serialize fleets data as plain array of ids
        let fleets = []
        if (user?.assigned_fleets?.length) {
          fleets = user.assigned_fleets.map((x) => {
            return x.id
          })
        }

        return {
          profile_pic: user?.profile?.profile_pic,
          role_id: user?.role?.id,
          role_name: user?.role?.role_name,
          username: user?.username,
          ranger_job_type: user?.profile?.ranger_job_type,
          full_name: user.full_name,
          country_code: user.country_code,
          phone_number: user.phone_number,
          email: user.email,
          // password is actually undefined
          password: user.password,
          birthday: user?.profile.birthday,
          gender: user?.profile.gender,
          assigned_fleets: [...fleets],
          preferred_currency: user?.preferred_currency?.id,
        }
      }
      return null
    },
    getStep2FormData() {
      if (this.userData) {
        const paymentInfo = this.userData?.payment_info
        return paymentInfo
          ? {
              account_name: paymentInfo?.account_name,
              account_number: paymentInfo?.account_number,
              branch_name: paymentInfo?.branch_name,
              country: paymentInfo?.country,
              routing_number: paymentInfo?.routing_number,
              is_banking_details_required:
                this.userData?.is_banking_details_required || false,
            }
          : null
      }
      return null
    },
    getStep3FormData() {
      if (this.userData) {
        const documentInfo = this.userData?.document_info
        if (!documentInfo) return null
        return {
          nid_number: documentInfo?.nid_number,
          nid_front_side_pic: documentInfo?.nid_front_side_pic,
          nid_back_side_pic: documentInfo?.nid_back_side_pic,
          is_verified: documentInfo?.is_verified,
          has_driving_license: documentInfo?.has_driving_license,
          is_document_required: this.userData?.is_document_required || false,
        }
      }
      return null
    },
    getStep4FormData() {
      if (this.userData) {
        const addressInfo = this.userData?.address_info
        return {
          // current address
          current_address_country: addressInfo.current_address_country,
          current_address_zip_code: addressInfo.current_address_zip_code,
          current_address_full: addressInfo.current_address_full,
          // permanent adress
          permanent_address_country: addressInfo.permanent_address_country,
          permanent_address_zip_code: addressInfo.permanent_address_zip_code,
          permanent_address_full: addressInfo.permanent_address_full,
          is_address_required: this.userData?.is_address_required || false,
        }
      }
      return null
    },
  },
  async created() {
    // todo: loader
    this.roles = await this.$http
      .get(roleApi.index)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
    this.roles.push({
      id: this.$store.getters['auth/roleInfo'].id,
      role_name: 'Owner',
    })
    this.fleets = await this.$http
      .get(FleetConfig.api.index)
      .then((res) => res.data.data)
      .catch((err) => console.log('err = ', err))
    this.countries = await this.$http
      .get(DropdownConfig.api.country)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
    this.currencies = await this.$http
      .get(DropdownConfig.api.organizationCurrencies)
      .then((res) => res.data)
      .catch((err) => console.log(err))
    // alert('user add edit data loaded')
  },
  mounted() {
    // reset current step to 1 on closed
    this.$edgeStack.emitter.on(
      this.$edgeStack.getEventName('closed', this.esId),
      () => {
        this.stepperCurrentStep = 1
      }
    )
  },
  beforeDestroy() {
    this.$edgeStack.emitter.off(
      this.$edgeStack.getEventName('closed', this.esId)
    )
  },
  watch: {
    // $data.theUserId needs to be in sync with the prop.userData.id (getUserId),
    // it's used as a proxy var as step 1 need to be able to update it from save event (via onSave)
    //
    // It's required for all steps of edit mode & even in add mode (except step 1 add mode)
    //
    // It's also updated from the save event listener of step 1 via onSave().
    // No need to listen on other step's save event as it should be the same id.
    userId: {
      immediate: true,
      handler(updatedId) {
        // getUserId -> updatedId could be null (in add mode)
        if (updatedId) {
          this.theUserId = updatedId
        }
      },
    },
    stepperStep: {
      immediate: true,
      handler(updatedStep) {
        this.stepperCurrentStep = updatedStep
      },
    },
  },
  methods: {
    onDirty(type, id = this.esId) {
      return type === true
        ? this.$edgeStack.shouldConfirm(id)
        : this.$edgeStack.shouldNotConfirm(id)
    },
    onSave(e = { step: null, data: {} }) {
      console.log(e)
      if (e.step === 1) {
        // if it's in add mode, user shouldn't be able to re submit step 1
        // or if the api supports it, it needs to be a patch request
        // if theUserId is already set & mode is strict -> it means user is trying
        // to get back to step 1 & resubmit

        // update theUserId
        this.theUserId = e.data.id
        // free the first 2 steps
        this.$xStepper.defineSteps(this.esId, [
          { title: 'Profile', free: true },
          { title: 'Payment', free: true },
          { title: 'Document', free: false },
          { title: 'Address', free: false },
        ])

        // define what happens about confirm before exit on next step (2)
        // on edit mode->after updating->should not confirm unless event triggered by user
        // on add mode->after saving->should confirm
        if (this.stepperMode === 'free') {
          this.$edgeStack.shouldNotConfirm(this.esId)
        } else {
          this.$edgeStack.shouldConfirm(this.esId)
        }

        this.$xStepper.navigate(this.esId).next()
      }
      if (e.step === 2) {
        // free the first 3 steps
        this.$xStepper.defineSteps(this.esId, [
          { title: 'Profile', free: true },
          { title: 'Payment', free: true },
          { title: 'Document', free: true },
          { title: 'Address', free: false },
        ])

        // define what happens about confirm before exit on next step (3)
        // on edit mode->after updating->should not confirm unless event triggered by user
        // on add mode->after saving->should confirm
        if (this.stepperMode === 'free') {
          this.$edgeStack.shouldNotConfirm(this.esId)
        } else {
          this.$edgeStack.shouldConfirm(this.esId)
        }

        this.$xStepper.navigate(this.esId).next()
      }
      if (e.step === 3) {
        // free all steps
        this.$xStepper.defineSteps(this.esId, [
          { title: 'Profile', free: true },
          { title: 'Payment', free: true },
          { title: 'Document', free: true },
          { title: 'Address', free: true },
        ])

        // define what happens about confirm before exit on next step (4)
        // on edit mode->after updating->should not confirm unless event triggered by user
        // on add mode->after saving->should confirm
        if (this.stepperMode === 'free') {
          this.$edgeStack.shouldNotConfirm(this.esId)
        } else {
          this.$edgeStack.shouldConfirm(this.esId)
        }

        this.$xStepper.navigate(this.esId).next()
      }
      if (e.step === 4) {
        // free only first step -> as we'r about to reset & close
        this.stepperSteps = [
          { title: 'Profile', free: true },
          { title: 'Payment', free: false },
          { title: 'Document', free: false },
          { title: 'Address', free: false },
        ]

        // Should not confirm as we'r about to close it
        this.$edgeStack.shouldNotConfirm(this.esId)
        this.$edgeStack.close(this.esId)

        this.$edgeStack.emitter.on(
          this.$edgeStack.getEventName('closed', this.esId),
          () => {
            this.stepperCurrentStep = 1
          }
        )

        // Should I emit refresh event only on add (strict) mode?
        window.dispatchEvent(new Event(this.$config.orgUser.events.refresh))
      }
    },
    onSubmit(step) {
      this.$refs[`step${step.step}`].submit()
    },
  },
}
</script>

<style scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
  margin-top: -30px;
  margin-bottom: 6px;
}
</style>
