<template>
  <div @click="toggle(id)" class="relative" :style="triggerStyle">
    <slot v-bind:triggerStyle="triggerStyle"></slot>
  </div>
</template>

<script>
import { ZIndex } from '@/config/StyleConfig'

export default {
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  computed: {
    triggerStyle: function() {
      return `z-index: ${ZIndex.dropdown.trigger} !important`
    },
  },
  mounted: function() {
    // console.log({ a: ZIndex.dropdown.trigger });
  },
  methods: {
    // fire event
    open(custom = null) {
      let eventName = custom !== null ? custom : this.id
      let c = dispatchEvent(new Event(this.getOpeningEventName(eventName)))
      console.log(c)
      // let o = dispatchEvent(new Event(this.openingEvent));
      //console.log(o)
    },
    close(custom = null) {
      let eventName = custom !== null ? custom : this.id
      dispatchEvent(new Event(this.getClosingEventName(eventName)))
      // let c = dispatchEvent(new Event(this.closingEvent));
      //console.log(c)
    },
    toggle(custom = null, awaitFor = 100) {
      let eventName = custom !== null ? custom : this.id
      let xEventName = this.getTogglingEventName(eventName)
      if (typeof awaitFor === 'number') {
        setTimeout(function() {
          dispatchEvent(new Event(xEventName))
        }, awaitFor)
        return
      }
      dispatchEvent(new Event(xEventName))

      return
      // let t = dispatchEvent(new Event(this.togglingEvent));
      //console.log(t)
    },
    getOpeningEventName(id) {
      return `xd-open-${id}`
    },
    getClosingEventName(id) {
      return `xd-close-${id}`
    },
    getTogglingEventName(id) {
      return `xd-toggle-${id}`
    },
  },
}
</script>

<style></style>
