<template>
  <t-modal
    ref="modalRef"
    :name="name"
    :variant="`vehicleLocation`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
  >
    <header class="py-4 px-3">
      <div class="flex justify-between modal-header">
        <div class="w-full text-left">
          <div class="gm-title">{{ title }}</div>
          <div class="gm-subtitle">{{ subtitle }}</div>
        </div>

        <div class="gm-lat-lng-area" @click="copyToClipboard">
          <div>
            <div class="text-14px">
              <span class="font-medium text-14px text-oDark">Lat: </span>
              <span class="text-oGray overflow-clip">
                {{ getLat || '--' }}
              </span>
            </div>
            <div class="text-14px">
              <span class="font-medium text-oDark">Long: </span>
              <span class="text-oGray overflow-clip">
                {{ getLng || '--' }}
              </span>
            </div>
          </div>
          <div class="text-right cursor-pointer">
            <i class="far fa-copy" />
          </div>
        </div>
      </div>
    </header>

    <section class="w-full h-96">
      <gmap-map
        ref="map"
        :zoom="zoom"
        :center="center"
        :map-type-id="`terrain`"
        :style="`width: 100%; height: 100%;`"
        @click="setMarker"
      >
        <gmap-marker
          v-if="marker.lat && marker.lng"
          :position="marker"
          :draggable="false"
          :icon="{
            url: icon,
            scaledSize: { width: 35, height: 40 },
          }"
        />
      </gmap-map>
    </section>

    <div class="flex gap-4 justify-end py-2 px-4 bg-white modal-header">
      <AppButton :text="'Cancel'" :variant="'secondary'" @click="onCancel" />
      <AppButton :text="'Save'" :variant="'primary'" @click="onSave" />
    </div>
  </t-modal>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'

import CopyToClipboardMixin from '@/mixins/copyToClipboardMixin'
import AppButton from '../form/AppButton.vue'

export default {
  name: 'LocationModal',

  mixins: [CopyToClipboardMixin],

  components: { AppButton },

  props: {
    name: {
      type: String,
      default: 'locationModal',
    },

    title: {
      type: [String, Number],
      default: 'Drag PIN to locate exact location',
    },

    subtitle: {
      type: [String, Number],
      default: '',
    },

    latLng: {
      type: String, // ds: '23,90'
      required: false,
    },

    icon: {
      default: () => require('@/assets/img/icons/map_marker@2x.png'),
    },

    updatable: {
      type: Boolean,
      default: false,
    },

    useGeocoder: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    google: gmapApi,

    getLat() {
      const lat = this?.latLng?.split(',')[0]
      return lat ? Number(lat) : null
    },

    getLng() {
      const lng = this?.latLng?.split(',')[1]
      return lng ? Number(lng) : null
    },

    getLatLngUrl() {
      return `https://www.google.com/maps/search/?api=1&query=${this.latLng}`
    },
  },

  data() {
    return {
      zoom: 11,

      center: {
        lat: 10,
        lng: 10,
      },

      marker: {
        lat: null,
        lng: null,
      },

      locationMode: false,
    }
  },

  watch: {
    latLng: {
      immediate: true,
      deep: false,
      handler() {
        this.setLatLng()
      },
    },
  },

  methods: {
    setLatLng() {
      if (!this.getLat && !this.getLng) return

      this.center = { lat: this.getLat, lng: this.getLng }
      this.marker = { ...this.center }
    },

    setMarker(e) {
      if (this.updatable) {
        this.marker = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        }

        this.$emit('update:marker', { e, marker: this.marker })
      }
    },

    copyToClipboard() {
      // using mixin
      this.copyContentToClipboard(this.getLatLngUrl, {
        important: true,
        title: 'Lat Long Copied',
        text: 'Lat & Long has been copied to your clipboard',
      })
    },

    async geocoder({ marker }) {
      let res
      const geocoder = new this.google.maps.Geocoder()
      await geocoder.geocode({ location: marker }, (results, status) => {
        if (status === 'OK') {
          if (results[1]) {
            // @see google geocder respose
            res = results[1]
          } else {
            console.error('LocationModal: No geocoding results found!')
          }
        }
      })

      return res
    },

    async onSave() {
      const marker = this.marker

      if (this.useGeocoder) {
        const geocoder = new this.google.maps.Geocoder()
        await geocoder.geocode({ location: marker }, (results, status) => {
          if (status === 'OK') {
            if (results[1]) {
              // @see google geocder respose
              this.$emit('save', { geocoder: results[1], marker })
              this.$refs.modalRef.hide()
            } else {
              console.error('LocationModal: No geocoding results found!')
              this.$emit('save', { geocoder: null, marker })
              this.$refs.modalRef.hide()
            }
          }
        })
      } else {
        this.$emit('save', { geocoder: null, marker })
        this.$refs.modalRef.hide()
      }
    },

    onCancel() {
      this.$emit('cancel')
      this.$refs.modalRef.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
.drag {
  font-size: 16px;
  font-weight: bold;
  color: #2e2e39;
}

.gm-title {
  font-size: 19px;
  font-weight: bold;
  color: #2e2e39;
}

.gm-subtitle {
  font-size: 14px;
  font-weight: medium;
  @apply text-oLightGray;
}

.gm-lat-lng-area {
  width: 230px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d3d3d3;
  background-color: #f8f8f8;
  @apply flex justify-between items-center mt-1 text-left px-4 cursor-pointer;
}
</style>
