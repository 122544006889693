<template>
  <ValidationProvider
    ref="providerRef"
    v-slot="{ errors, required, ariaInput, ariaMsg }"
    :class="`relative w-full appearance-none`"
    :tag="`div`"
    :vid="vid || name"
    :name="name || vid"
    :rules="rules"
  >
    <label
      class="block pb-1 w-full font-semibold leading-normal text-14px"
      @click="focus"
      :for="name"
      :class="{
        'text-gray-700': !errors[0],
        'text-red-600': errors[0] || customError != '',
      }"
    >
      <div v-if="label">
        <span>{{ label }}</span>
        <span>{{ required ? ' *' : '' }}</span>
      </div>
    </label>

    <template v-if="type === 'tel'">
      <vue-tel-input
        ref="inputRef"
        v-model="valueProxy"
        v-bind="{ ...$attrs }"
        v-on="getListeners"
        :class="{
          'border-gray-400 placeholder-gray-500 telinput':
            !errors[0] || customError == '',
          'border-red-600 placeholder-red-400 custom-phone': errors[0],
          'bg-oLightestGray text-oLightGray placeholder-oLightGray telinput': disabled,
          'has-value telinput': hasValue,
        }"
        validCharactersOnly
        :disabled="disabled"
        @input="onChangePhoneNumber"
      >
      </vue-tel-input>
    </template>

    <template v-else-if="type == 'textarea'">
      <textarea
        ref="inputRef"
        v-model="valueProxy"
        v-bind="{ ...ariaInput, ...$attrs }"
        v-on="getListeners"
        :disabled="disabled"
        class="block py-1 px-4 w-full h-20 leading-normal bg-white rounded border border-gray-400 outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
        :class="{
          'border-gray-400 placeholder-gray-500': !errors[0],
          'border-red-600 placeholder-red-400': errors[0],
          'has-value': hasValue,
          'bg-oLightestGray text-oLightGray placeholder-oLightGray': disabled,
        }"
      />
    </template>

    <template v-else-if="type === 'password'">
      <div class="relative">
        <input
          ref="inputRef"
          v-model="valueProxy"
          v-bind="{ ...ariaInput, ...$attrs }"
          v-on="getListeners"
          :type="typeProxy"
          :disabled="disabled"
          class="block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
          :class="{
            'border-gray-400 placeholder-gray-500': !errors[0],
            'border-red-600 placeholder-red-400': errors[0],
            'has-value': hasValue,
            'bg-oLightestGray text-oLightGray placeholder-oLightGray': disabled,
          }"
        />

        <div class="eye-body" @click="onTogglePasswordFeild">
          <i v-if="typeProxy === 'text'" class="far fa-eye" />
          <i v-if="typeProxy === 'password'" class="far fa-eye-slash" />
        </div>
      </div>
    </template>

    <template v-else-if="type === 'richselect'">
      <TRichSelect
        ref="inputRef"
        v-model="valueProxy"
        v-bind="{ ...ariaInput, ...$attrs }"
        v-on="getListeners"
        :delay="delaySearchTime"
        :disabled="disabled"
        :variant="{ danger: !!errors[0], readonly: disabled }"
      />
    </template>

    <template v-else>
      <input
        v-if="type === 'date'"
        ref="inputRef"
        v-model="valueProxy"
        v-bind="{ ...ariaInput, ...$attrs }"
        v-on="getListeners"
        :type="type"
        :max="max"
        :disabled="disabled"
        class="block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
        :class="{
          'border-gray-400 placeholder-gray-500': !errors[0],
          'border-red-600 placeholder-red-400': errors[0],
          'bg-oLightestGray text-oLightGray placeholder-oLightGray': disabled,
          'has-value': hasValue,
        }"
      />
      <input
        v-else
        ref="inputRef"
        v-model="valueProxy"
        v-bind="{ ...ariaInput, ...$attrs }"
        v-on="getListeners"
        :type="type"
        :disabled="disabled"
        class=" block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
        :class="{
          'border-gray-400 placeholder-gray-500': !errors[0],
          'border-red-600 placeholder-red-400': errors[0],
          'bg-oLightestGray text-oLightGray placeholder-oLightGray': disabled,
          'has-value': hasValue,
          'outline-none px-2 appearance-none ring-0 focus:ring-0 focus:outline-none': isSearch,
        }"
      />
    </template>

    <!-- errors -->
    <div v-if="customError == ''" class="flex items-center min-h-6">
      <span
        v-bind="ariaMsg"
        v-show="errors[0]"
        v-text="errors[0]"
        class="block text-xs text-red-600"
      />
    </div>

    <div v-if="customError != ''" class="flex items-center min-h-6">
      <span
        v-bind="ariaMsg"
        v-show="customError"
        v-text="customError"
        class="block text-xs text-red-600"
      />
    </div>
  </ValidationProvider>
</template>

<script>
// TODO: add custom error to the vee-validate instance
/*
 * props.type should be immutable, props.type is watched & synced with data.typegProxy
 * type should be used for checking the real input type, while typeProxy can be used for
 * mutating the type & use inside.
 * e.g. on showing & hiding password, it needs mutate the input type
 **/

const validTypes = [
  'url',
  'text',
  'email',
  'search',
  'number',

  'date',

  'textarea',

  'tel',
  'password',
  'richselect',
]

export default {
  name: 'AppInput',

  inheritAttrs: false,

  components: {},

  props: {
    vid: {
      type: String,
      default: undefined,
    },

    name: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
      validator(value) {
        return validTypes.includes(value)
      },
    },
    max: {
      type: String,
    },
    label: {
      type: String,
      default: '',
    },

    rules: {
      type: [Object, String],
      default: '',
    },

    value: {
      type: null,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    // todo: wip
    variant: {
      type: null,
      default: '',
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    delaySearchTime: {
      type: Number,
      required: false,
      default: 400,
    },
  },

  data: () => ({
    typeProxy: '',
    valueProxy: '',
    customError: '',
  }),

  computed: {
    hasValue() {
      return !!this.valueProxy
    },

    getListeners() {
      return Object.assign({}, this.$listeners, {
        // handle custom event as well as a fallback to the native events
        input: (event) => {
          const e =
            this.type === 'richselect' || this.type === 'tel'
              ? event
              : event.target.value
          this.$emit('input', e)
        },
      })
    },
  },

  watch: {
    value: {
      deep: false,
      immediate: true,
      handler(val) {
        if (val !== this.valueProxy) {
          this.valueProxy = val
        }
      },
    },

    type: {
      deep: false,
      immediate: true,
      handler(val) {
        if (val !== this.typeProxy) {
          this.typeProxy = val
        }
      },
    },

    // This is now handled by getListeners
    /* valueProxy: {
      deep: false,
      immediate: false,
      handler(val) {
        this.$emit('input', val)
      },
    }, */
  },

  methods: {
    onChangePhoneNumber(number, valid) {
      if (valid.isValid) {
        //true
        this.valueProxy = valid.number.input
        this.customError = ''
        // this.$emit('tel:valid', {
        //   valid: true,
        //   message: 'The phone number field is valid',
        // })
      } else {
        // this.$emit('tel:invalid', {
        //   valid: false,
        //   message: 'The phone number field must be a valid',
        // })
        // this.customError = 'The phone number field must be a valid'
      }
    },

    onTogglePasswordFeild() {
      this.typeProxy = this.typeProxy === 'password' ? 'text' : 'password'
    },

    focus() {
      this.$refs.inputRef.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
.min-h-6 {
  min-height: 1.5rem;
}
.eye-body {
  position: absolute;
  top: 0px;
  right: 0px;
  top: 8px;
  right: 11px;
  cursor: pointer;
  i {
    color: #00cb5d;
  }
}
</style>
