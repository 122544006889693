<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <div class="mb-6 border-b">
        <TCheckbox
          v-model="skipOperatorApp"
          :label="`I Do Not Need Operator App Configuration`"
          :name="`I Do Not Need Operator App Configuration`"
          wrapped
        />
      </div>

      <text-input
        v-model="form.charging_task.rate"
        rules=""
        label="Charging - Earning Rate"
        placeholder="e.g. $20"
        :disabled="skipOperatorApp"
      />

      <text-input
        rules=""
        label="Charging - Point"
        placeholder="e.g. 5"
        v-model="form.charging_task.point"
        :disabled="skipOperatorApp"
      />

      <text-input
        rules=""
        label="Rebalance - Earning Rate"
        placeholder="e.g. $20"
        v-model="form.rebalance_task.rate"
        :disabled="skipOperatorApp"
      />

      <text-input
        rules=""
        label="Rebalance - Point "
        placeholder="e.g. 5"
        v-model="form.rebalance_task.point"
        :disabled="skipOperatorApp"
      />

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { useEndpoints } from '@/composables'
import { deepCompareObjects } from '@/utils'

import TextInput from '@/components/form/TextInput'

export default {
  name: 'Step4',

  components: {
    TextInput,
  },

  props: {
    primaryKey: {
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,

      skipOperatorApp: false,

      form: {
        charging_task: {
          rate: '',
          point: '',
        },

        rebalance_task: {
          rate: '',
          point: '',
        },
      },
      permanentAddressIsSame: false,
    }
  },

  watch: {
    // sync props.formData with $data.from
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          this.form = { ...data }
        }
      },
    },
    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        // don't notify if editing data & user input data are same
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // otherwise notify whenever there's new input / change
        this.$emit('dirty', { step: 4, data: this.form })
      },
    },
  },

  methods: {
    async submit() {
      await this.$refs.submitButton.click()
    },
    async submitStep() {
      // charging
      const chargingUrl = useEndpoints.fleet.operator.charging(this.primaryKey)

      const chargingData = new FormData()
      for (const k in this.form.charging_task) {
        chargingData.append(k, this.form.charging_task[k])
      }

      const chargingReq = this.$http.patch(chargingUrl, chargingData)

      // rebalance
      const rebalanceUrl = useEndpoints.fleet.operator.rebalance(
        this.primaryKey
      )

      const rebalanceData = new FormData()
      for (const k in this.form.rebalance_task) {
        rebalanceData.append(k, this.form.rebalance_task[k])
      }

      const rebalanceReq = this.$http.patch(rebalanceUrl, rebalanceData)

      // submit
      this.isLoading = true
      await this.$http
        .all([chargingReq, rebalanceReq])
        .then(
          this.$http.spread((...responses) => {
            const [chargingRes, rebalanceRes] = responses

            const data = {
              charging_task: chargingRes.data,
              rebalance_task: rebalanceRes.data,
            }

            console.log('submit3', data)

            this.$emit('save', { step: 3, data })

            const message = `Fleet ${
              this.isEditing ? 'updated' : 'added'
            } successfully`

            this.$notify({
              group: 'bottomLeft',
              type: 'success',
              title: 'Success',
              text: message,
            })
          })
        )
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response.data.detail,
          })
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 22px;
  font-weight: bold;
  color: #2e2e39;
}
</style>
