export const OrganizationUserConfig = {
  api: {
    // get
    index: '/dashboard/org-users/',
    // get
    single: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/`
    },
    // post
    create: '/dashboard/org-users/',
    payment_create: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/payment-info/`
    },

    document_create: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/document-info/`
    },
    address_create: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/address-info/`
    },

    // patch
    update: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/`
    },
    // post
    activate: `/dashboard/org-users/activate/`,
    // post
    verifyDocs: (id = 'uuid') => {
      return `/dashboard/org-users/${id}/document-info/`
    },
    // put
    updateStatus: (userId = 'uuid') => {
      return `/dashboard/org-users/${userId}/update-status/`
    },
  },
  events: {
    name: 'org-user',
    // refresh-index-data
    refresh: `rid-org-user`,
    // slideover-right
    sorId: 'org-user',
    sorOpen: 'sor-open-org-user',
    sorClose: 'sor-close-org-user',
    sorToggle: 'sor-toggle-org-user',
    // editing-data
    editingData: 'edit-org-user-data',
    // viewing-data
    viewingData: 'view-org-user-data',

    succeededStep1: 'succeeded-step-1',
    succeededStep2: 'succeeded-step-2',
    succeededStep3: 'succeeded-step-3',
    succeededStep4: 'succeeded-step-4',
  },
}
