<template>
  <base-layout>
    <FleetAddEdit
      :es-id="ae.esId"
      :stepper-mode="ae.stepperMode"
      :stepper-step="ae.stepperStep"
      :primary-key="ae.primaryKey"
      :raw-data="ae.rawData"
      :busy="ae.busy"
      :operator-app-enabled="hasOperatorApp"
      :org-email-settings="orgEmailSettings"
      @refresh="$store.dispatch('fsTable/fetchData')"
    />

    <content-section :spacing="false">
      <div class="content-spacing">
        <title-plus
          title="Fleets"
          @plus="onOpenES({ primaryKey: null })"
          class="mb-10"
        />
      </div>

      <FSTable
        :fst-id="`fleets`"
        :endpoint="indexDataEndpoint"
        :headers="tableHeaders"
      >
        <template v-slot:default="{ data }">
          <FSTableRow v-for="(item, itemIndex) in data" :key="itemIndex">
            <FSTableRowItem
              :text="item.id"
              :truncate="-5"
              :to="{ name: 'ViewFleetProfile', params: { id: item.id } }"
            />

            <FSTableRowItem :text="item.name" />

            <FSTableRowItem :text="item.address" />

            <FSTableRowItem>
              <XStatus
                :variant="item.visibility ? 'green' : 'orange'"
                :text="item.visibility ? 'Public' : 'Private'"
                profile="fleet"
              />
            </FSTableRowItem>

            <FSTableRowItem :text="item.email_address" />

            <FSTableRowItem :text="item.created_at" date />

            <FSTableRowItem>
              <div class="flex items-center 111__111">
                <OtoEditIcon @click="onOpenES({ primaryKey: item.id })" />

                <MoreActionsDropdown
                  :key="`more-actions-${itemIndex}`"
                  :data="item"
                  @delete="$store.dispatch('fsTable/fetchData')"
                />
              </div>
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
// import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import TitlePlus from '@/components/ui/TitlePlus'

import { useEndpoints } from '@/composables'
import { FleetConfig } from '@/config/FleetConfig'

// import OtoSearch from '@/components/ui/OtoSearch'
import XStatus from '@/components/badge/XStatus'
import MoreActionsDropdown from '@/composites/fleet/index/MoreActionsDropdown.vue'

import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import FleetAddEdit from './FleetAddEdit.vue'
export default {
  name: 'FleetIndex',

  components: {
    BaseLayout,
    ContentSection,
    OtoEditIcon,
    XStatus,
    TitlePlus,
    MoreActionsDropdown,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FleetAddEdit,
  },

  data() {
    return {
      indexDataEndpoint: FleetConfig.api.index,

      tableHeaders: [
        { text: 'Fleet ID', width: '10%', sort: 'id' },
        { text: 'Fleet Name', width: '12%', sort: 'name' },
        { text: 'Location', width: '28%', sort: 'address' },
        { text: 'Visibility', width: '10%', sort: 'visibility' },
        { text: 'Support Email', width: '15%', sort: 'email_address' },
        { text: 'Date Created', width: '15%', sort: 'created_at' },
        { text: 'Actions', width: '10%', sort: null },
      ],

      orgEmailSettings: {},

      // add-edit
      ae: {
        // EdgeStack component
        esId: 'fleet-add-edit',

        // XStepper component
        stepperMode: 'strict',
        stepperStep: 1,

        // Data
        busy: true,
        rawData: null,
        primaryKey: null,
      },
    }
  },

  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    hasOperatorApp() {
      // console.log('hasOperatorApp?', this.$org.subscription.operator_app_enabled)
      return this.$org.subscription.operator_app_enabled
    },
  },

  async created() {
    await this.$http
      .get(useEndpoints.settings.email.index(this.orgId))
      .then(({ data }) => {
        // replace null as empty string
        const formatted = {}
        for (const k in data) {
          formatted[k] = data[k] === 'null' ? '' : data[k]
        }

        this.orgEmailSettings = formatted
      })
      .catch((err) => {
        console.log('orgEmailSettingsErr', err.response)
        this.$notify({
          group: 'bottomRight',
          type: 'error',
          title: 'HTTP Error',
          text: 'Failed to load org email settings!',
        })
      })
  },

  methods: {
    async onOpenES({ primaryKey }) {
      if (typeof primaryKey === 'string') {
        this.ae.busy = true
        this.$edgeStack.open(this.ae.esId)

        const fleetReq = this.$http.get(useEndpoints.fleet.details(primaryKey))

        const requests = [fleetReq]
        if (this.hasOperatorApp) {
          const operatorChargingReq = this.$http.get(
            useEndpoints.fleet.operator.charging(primaryKey)
          )
          const operatorRebalanceReq = this.$http.get(
            useEndpoints.fleet.operator.rebalance(primaryKey)
          )

          requests.push(operatorChargingReq, operatorRebalanceReq)
        }

        await this.$http
          .all(requests)
          .then(
            this.$http.spread((...responses) => {
              // console.log(fleetRes, chargingRes, rebalanceRes)

              let data = {}
              if (this.hasOperatorApp) {
                const [fleetRes, chargingRes, rebalanceRes] = responses
                data = {
                  ...fleetRes.data,
                  charging_task: chargingRes.data,
                  rebalance_task: rebalanceRes.data,
                }
              } else {
                const [fleetRes] = responses
                data = {
                  ...fleetRes.data,
                  charging_task: {
                    point: '',
                    rate: '',
                  },
                  rebalance_task: {
                    point: '',
                    rate: '',
                  },
                }
              }

              // console.log(data)

              this.ae.rawData = data
              this.ae.primaryKey = primaryKey
              this.ae.stepperMode = 'free'

              this.ae.busy = false

              // console.log(this.ae.rawData)
              // ** open a random step (keeping it as a reference)
              // function randomNumber(min, max) {
              //   const r = Math.random() * (max - min) + min
              //   return Math.floor(r)
              // }
              // this.$edgeStack.emitter.on(
              //   this.$edgeStack.getEventName('opened', this.ae.esId),
              //   () => {
              //     this.$xStepper.navigate(this.ae.esId).to(randomNumber(0, 4))
              //   }
              // )
            })
          )
          .catch((err) => {
            console.warn({ err })

            this.$edgeStack.close(this.ae.esId)
            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Server Error`,
              text: 'Failed to retrieve data. Please try again.',
            })
          })

        return
      }

      this.ae.stepperMode = 'strict'

      this.ae.busy = false
      this.ae.rawData = null
      this.ae.primaryKey = null

      this.$edgeStack.open(this.ae.esId)
      // this.$xStepper.navigate(this.ae.esId).to(4)
    },

    getActionsEndpoint(id) {
      return FleetConfig.api.update(id)
    },
  },
}
</script>

<style></style>
